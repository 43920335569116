import styled from 'styled-components'
import AvatarEgg from '../components/Misc/AvatarEgg'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useActiveCourse } from './course/hooks/useActiveCourse'
import { ModalType, useUIContext } from '../context/UIContext'
import { useProfile } from './profile/hooks/useProfile'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { useTranslation } from 'react-i18next'

type Props = {}

const UserProfile = (props: Props) => {
    const { t } = useTranslation('profile')
    const navigate = useNavigate()
    const { dispatchModal } = useUIContext()
    const { user, displayNameOrName } = useProfile()
    const { activeCourse } = useActiveCourse()

    return (
        <Styled>
            <div className="car rounded">
                <div className="avatar-container">
                    <AvatarEgg title={displayNameOrName} isEditable={true} />
                </div>
                <div className="info-container">
                    <h1>{displayNameOrName}</h1>
                    <p>{user?.email}</p>
                </div>
                <div className="actions redesign">
                    <button
                        className="primary white"
                        onClick={() => dispatchModal({ type: ModalType.MY_PROFILE_DATA })}
                    >
                        {t('Edit profile')}
                    </button>
                </div>
            </div>
            <br />
            {activeCourse?.enableReferralCode !== false && (
                <>
                    {/* <section className="referrals">
                    <div style={{ flex: '1' }}>
                        <h2>{t('Connect to the referral program and win!')}</h2>
                        <p>
                            {t(
                                'Invite your friends to Egg and study for free any of our courses.'
                            )}
                        </p>
                    </div>
                    <button className="outlined" onClick={() => navigate('/referrals')}>
                        <FontAwesomeIcon className="btn-icon" icon={['far', 'megaphone']} />
                        {t('Go to Referrals')}
                    </button>
                </section> */}

                    <section className="section referrals">
                        <div>
                            <h3>{t('My certifications')}</h3>
                            <p>{t('Download your certificates obtained.')}</p>
                        </div>
                        <button className="outlined" onClick={() => navigate('/certificates')}>
                            <FontAwesomeIcon
                                className="btn-icon"
                                icon={['fal', 'file-certificate']}
                            />{' '}
                            {t('Certificates')}
                        </button>
                    </section>
                </>
            )}
        </Styled>
    )
}

export default UserProfile

const Styled = styled.section`
    .car {
        display: flex;
        align-items: center;
        gap: 4em;
        background-color: ${defaultTheme.color.primaryOpacity};
        padding: 2em 4em;
        color: ${defaultTheme.color.white};

        .info-container {
            margin: auto 0;

            & > * {
                margin: 0;
            }

            p {
                font-size: 1.25em;
                opacity: 0.6;
            }
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }
        .actions {
            flex-grow: 2;
            text-align: end;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 2em;
            padding: 3em 1em;
        }
    }
    .section {
        border-bottom: ${props => `1px solid ${props.theme.color.lightGray}`};
        padding-bottom: 50px;

        :last-child {
            border-bottom: 0;
        }
    }
    .referrals {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        padding: 4em 0;

        h3 {
            font-size: 18px;
            margin: 0 0 5px 0;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: normal;
            text-align: center;
        }
    }
`
