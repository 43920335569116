import { useToken } from '../../hooks/useToken'
import { ApiActivation } from '../../infrastructure/api-activation'
import { API_RESPONSE } from '../../interfaces/api-activation'
import { useQuery } from '@tanstack/react-query'
import { Icon } from '../Icon'
import styled from 'styled-components'
import { ModalType, useUIContext } from '../../context/UIContext'
import { useTranslation } from 'react-i18next'
import { numberFormatted } from '../../helpers/formatters'
import { HTMLAttributes } from 'react'

export const UserStatement = (props: HTMLAttributes<HTMLElement>) => {
    const { t } = useTranslation('wallet')
    const { dispatchModal } = useUIContext()
    const { statement, isPending } = useStatement()

    if (isPending || !statement || (!statement.coins && !statement.pulses && !statement.steps))
        return null

    const openGlossary = () => dispatchModal({ type: ModalType.USER_BALANCE_GLOSSARY })

    const { coins, steps, pulses } = statement
    return (
        <Styles {...props}>
            <button className="icon outlined" onClick={openGlossary} title={t('Pulses')}>
                <Icon icon={['fas', 'bolt']} />
                <span>{numberFormatted(pulses)}</span>
            </button>

            <button className="icon outlined" onClick={openGlossary} title={t('Steps')}>
                <Icon icon={['fas', 'shoe-prints']} rotation={270} />
                <span>{numberFormatted(steps)}</span>
            </button>

            <button className="icon outlined" onClick={openGlossary} title={t('EggCoins')}>
                <Icon icon={['fas', 'coin-vertical']} />
                <span>{numberFormatted(coins)}</span>
            </button>
        </Styles>
    )
}

const Styles = styled.section`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    padding: 1em 0;
    border-bottom: 1px solid #e5e5e5;
    & > button.icon {
        font-size: 1.3em;
        font-weight: normal;

        & + button {
            margin-left: 0;
        }

        & > span {
            margin-left: 0.5em;
            font-size: 1em;
        }
    }
    @media (max-width: 768px) {
        display: flex;
        gap: 5px;
        & > button.icon {
            padding: 0 !important;
        }
        & > button.icon > svg {
            font-size: 14px;
        }
        & > button.icon > span {
            margin-left: 4px;
            font-size: 1rem;
        }
    }
`

const useStatement = () => {
    const { _id } = useToken()

    const queryKey = ['User', 'Statement', _id]

    const queryFn = () =>
        ApiActivation.get<API_RESPONSE<UserStatementDTO>>('v1/users/statement').then(
            ({ data }) => data.data
        )

    const { data: statement, ...rest } = useQuery({ queryKey, queryFn, enabled: !!_id })

    return { statement, ...rest }
}

type UserStatementDTO = {
    coins: number
    steps: number
    pulses: number
}
