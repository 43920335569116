import { isProduction } from '../interfaces/constants'

export const useTikTokPixel = () => {
    const tikTokSend = (event?: TikTokSend) => {
        const eventToSend = event ? window.ttq?.track(event) : window.ttq?.page()
        const trackedEvent = event ? { event } : 'Page'
        return isProduction ? eventToSend : console.warn('New TikTok event', trackedEvent)
    }
    return { tikTokSend }
}

export const initializeTikTokPixel = (pixelId: string) => {
    const script = document.createElement('script')
    script.innerHTML = `
    !function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"]
        ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } };
        for (const element of ttq.methods) ttq.setAndDefer(ttq, element);
        ttq.instance = function (t) {
            for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)ttq.setAndDefer(e, ttq.methods[n]);
            return e
        }
        ttq.load = function (e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            ttq._i = ttq._i || {}
            ttq._i[e] = []
            ttq._i[e]._u = i
            ttq._t = ttq._t || {}
            ttq._t[e] = +new Date
            ttq._o = ttq._o || {}
            ttq._o[e] = n || {};
            n = document.createElement("script");
            n.type = "text/javascript"
            n.async = !0
            n.src = i + "?sdkid=" + e + "&lib=" + t;
            e = document.getElementsByTagName("script")[0];
            e.parentNode.insertBefore(n, e)
        };
    }(window, document, 'ttq');
    `
    document.body.appendChild(script)
    window.ttq.load?.(pixelId)
}

declare global {
    interface Window {
        ttq: TikTokPixel
    }
}

interface TikTokPixel {
    track: (eventName: string) => void
    load: (id: string) => void
    page: () => void
}

type TikTokSend = 'Lead' | 'Enrolled'
