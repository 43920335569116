import { CardGeneral, CardGeneralSkeleton } from '../../../components/CardGeneral'

import { AssignationBar } from '../../../components/CourseCard/AssignationBar'
import { Suspense } from 'react'
import { Title } from '../../../components/Title'
import { UserCourseDTOExtended } from '../../../interfaces/api-activation'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUserCourses } from '../../../hooks/queries/useUserCourses'

export const MyCourses = () => {
    const { t } = useTranslation('home')

    const { courses, isFetching } = useUserCourses()

    return (
        <div>
            <Title title={t('My courses')} size="large" />
            <br />
            <Grid>
                <Suspense fallback={<CardSkeleton />}>
                    {isFetching ? (
                        <CardSkeleton />
                    ) : courses?.length ? (
                        courses?.map(course => <Card {...course} key={course.courseId} />)
                    ) : (
                        <NoCourses />
                    )}
                </Suspense>
            </Grid>
        </div>
    )
}

const Card = (props: UserCourseDTOExtended) => {
    const { networkName, courseName } = props
    return (
        <CardGeneral
            header={null}
            main={
                <>
                    <div className="title">{networkName ?? courseName}</div>
                    <div className="subtitle"></div>
                </>
            }
            footer={
                <>
                    <AssignationBar isInside={false} {...props} />
                </>
            }
            className="card-general"
        />
    )
}

const CardSkeleton = () => <CardGeneralSkeleton className="card-general" />

const NoCourses = () => {
    const { t } = useTranslation('home')
    const navigate = useNavigate()
    return (
        <p>
            {t('You have not enrolled in any course yet, choose one from the section')}{' '}
            <button onClick={() => navigate('/ecosystem')} className="link">
                <b>{t('Ecosystem')}</b>
            </button>
        </p>
    )
}

const Grid = styled.div`
    display: flex;
    gap: 1.5em;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`
