import { isTokenExpired, testPasswordStrength } from '../../../../helpers/validators'

import { Input } from '../../../../components/FormElements/Input'
import { changePassword } from '../../../../infrastructure/api-activation'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ResetWithToken = ({ token }: Props) => {
    const queryClient = useQueryClient()
    const { t } = useTranslation('signIn')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>()
    const navigate = useNavigate()

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (password.trim() !== confirmPassword.trim())
            return setError(t('Passwords do not match.', { ns: 'signUp' }))

        const strength = testPasswordStrength(password, { special: false })
        if (typeof strength === 'string') return setError(t(strength, { ns: 'signUp' }))

        setIsLoading(true)
        changePassword({ token, password })
            .then(async () => {
                await queryClient.refetchQueries({ queryKey: ['User'] })
                navigate('/login')
            })
            .catch(({ message }) => setError(message))
            .finally(() => setIsLoading(false))
    }

    if (isTokenExpired(token))
        return (
            <section className="center" style={{ paddingTop: '50%' }}>
                <h3>{t('Oops! Your link has expired.')}</h3>
                <p className="light">
                    {t(
                        'It seems that the link you were trying to use is no longer available. This usually happens when more than 48 hours have passed since its issuance or if it has already been used.'
                    )}
                </p>
                <button
                    className="text"
                    children={t('Back', { ns: 'signUp' })}
                    onClick={() => navigate('/login')}
                />
            </section>
        )

    return (
        <form onSubmit={onSubmit}>
            <section>
                <h1 className="title">{t('Set New Password')}</h1>
                <br />
                <Input
                    value={password}
                    type="password"
                    label={t('New Password')}
                    onChange={({ target: { value } }) => setPassword(value)}
                />
                <br />
                <Input
                    value={confirmPassword}
                    type="password"
                    label={t('Confirm New Password')}
                    onChange={({ target: { value } }) => setConfirmPassword(value)}
                    error={error}
                />
                <br />
            </section>

            <br />

            <div className="buttons column">
                <button
                    type="submit"
                    className="primary"
                    children={t('New Password')}
                    disabled={isLoading}
                />

                <button
                    className="text"
                    children={t(`Cancel`)}
                    onClick={() => navigate('/login')}
                />
            </div>
        </form>
    )
}

interface Props {
    token: string
}
