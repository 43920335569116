import { API_RESPONSE } from '../../interfaces/api-activation'
import { ApiActivation } from '../../infrastructure/api-activation'

export const getEcosystem = (lang?: string) =>
    ApiActivation.get<GetEcosystemResponse>(`/v1/marketplace${lang ? `?lang=${lang}` : ''}`).then(
        ({ data }) => data.data
    )

export const getOneCourse = (slug: string) =>
    ApiActivation.get<GetNetworkResponse>(`/v1/networks/${slug}`).then(
        ({ data }) => data.data.network
    )

export const getCourseDetails = (slug: string) =>
    ApiActivation.get<GetDetailsResponse>(`/v1/networks/${slug}/details`).then(
        ({ data }) => data.data.details
    )

export const getFrequencies = (slug: string) =>
    ApiActivation.get<GetFrequenciesResponse>(`/v1/networks/${slug}/frequencies`).then(
        ({ data }) => data.data.frequencies
    )

export const getCohorts = async (courseId: string, limit?: number, from?: string) => {
    const limitParam = limit ? `&limit=${limit}` : ''
    const fromParam = from ? `&from=${from}` : ''
    const { data } = await ApiActivation.get<GetCohortResponse>(
        `/v1/networks/courses/${courseId}/quotas/available?${limitParam}${fromParam}`
    )
    return data.data.quotas
}

export const createProfile = (data: {
    cohortId: string
    networkTitle?: string
    frequency?: string
    schedule?: string
}) => ApiActivation.post<ProfileResponse>('/v1/profiles', data).then(({ data }) => data.data)

export const getProfile = (networkId: string) =>
    ApiActivation.get<ProfileResponse>(`/v1/profiles?networkId=${networkId}`).then(
        ({ data }) => data.data
    )

type GetNetworkResponse = API_RESPONSE<{ network: NetworkDTO }>
type GetDetailsResponse = API_RESPONSE<{ details: DetailsDTO }>

export interface NetworkDTO {
    id: string
    title: string
    slug: string | null
    contentType: string
    detailId?: string
    details?: Detail
    description: string
    flags: Flags
    isAsync: boolean
}

interface Flags {
    isPWDC: boolean
    requirePWDC: boolean
    isFree: boolean
    warning: string
}

interface Detail {
    id: string
    steps: number
    status: string
    short_description: string
    long_description?: string
    price_usd: string
    contentful_id?: string
}

export interface DetailsDTO {
    id: string
    title: string
    steps: number
    eggCoins: number
    shortDescription: string
    longDescription: string
    studyPlan: { title: string }[]
    lang: string
    tags: {
        name: string
        color: string
    }[]
}

type GetFrequenciesResponse = API_RESPONSE<{ frequencies: FrequencyDTO[] }>

export interface FrequencyDTO {
    id: string
    networkId: string
    description: string
    title: 'Intensive' | 'Moderate'
    days: number[]
    courses: {
        id: string
        frequencyId: string
        from: number
        to: number
    }[]
}

export type GetCohortResponse = API_RESPONSE<{ count: number; quotas: CohortDTO[] }>
export interface CohortDTO {
    id: string
    courseId: string
    date: string
    slots: number
}

type ProfileResponse = API_RESPONSE<ProfileResponseDTO>
export interface ProfileResponseDTO {
    complete: boolean
    courseId: string
    quotaId?: string
    date: string
    networkTitle: string
    frequencyId: string
    frequencyTitle: string
    frequencyDays: number[]
    frequencyRange: {
        from: number
        to: number
    }
    user: {
        displayName: string
        email: string
        country: string
        phone: string
    }
}

export type UserChoices = {
    chosen_network_id: string
    chosen_network_name: string
    chosen_rhythm: string
    chosen_day_of_study: string
    chosen_hours_of_study: string
    chosen_start_date: string
    chosen_start_date__formato_fecha_: string
    user_timezone: string
}

type GetEcosystemResponse = API_RESPONSE<GetEcosystem>
export interface GetEcosystem {
    organizations: {
        id: string
        name: string
        logo: string | null
    } | null
    courses: EcosystemCourseDTO[]
}

export interface EcosystemCourseDTO {
    id: string
    title: string
    slug: string
    lang: string
    description: string
    flags: {
        isFree: boolean
        isPublic: boolean
        warning: string
    }
    tags: Tag[]
}

export interface Tag {
    name: string
    color: string
}
