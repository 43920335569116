import { Outlet } from 'react-router-dom'
import { ResponsiveLayout } from '../../layouts/ResponsiveLayout'
import { Footer } from '../../components/Footer'
import { UserStatement } from '../../components/NavBar/UserStatement'

export const GeneralLayout = () => (
    <ResponsiveLayout header={null} content={<Outlet />} aside={null} footer={<Footer />} />
)

export const HelloCenterLayout = () => (
    <ResponsiveLayout
        header={<UserStatement />}
        content={<Outlet />}
        aside={null}
        footer={<Footer />}
    />
)
