import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut as firebaseSignOut,
    UserCredential,
} from 'firebase/auth'

import { initializeApp } from 'firebase/app'
import { postAuth } from './api-activation'

const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_AUTH_DOMAIN,
    REACT_APP_FIREBASE_DATABASE_URL,
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_STORAGE_BUCKECT,
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env

const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKECT,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth(firebaseApp)
const providerGoogle = new GoogleAuthProvider()
providerGoogle.addScope('https://www.googleapis.com/auth/userinfo.profile	')
const providerFacebook = new FacebookAuthProvider()

export const currentUser = auth.currentUser

export const createUserWithCredentials = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password)

export const signInWithUsername = async (
    email: string,
    password: string,
    captcha_token: string
) => {
    const credentials = await signInWithEmailAndPassword(auth, email, password)
    return await parseIdToken(credentials, captcha_token)
}

export const signInWithGoogle = async (captcha_token: string) => {
    const credentials = await signInWithPopup(auth, providerGoogle)
    return await parseIdToken(credentials, captcha_token)
}

export const signInWithFacebook = async (captcha_token: string) => {
    const credentials = await signInWithPopup(auth, providerFacebook)
    return await parseIdToken(credentials, captcha_token)
}

export const fbSignOut = async () => await firebaseSignOut(auth)

const parseIdToken = async (
    credential: UserCredential & { _tokenResponse?: UserCredentialTokenResponse },
    captcha_token: string
) => {
    const { user, _tokenResponse } = credential
    if (!user || !_tokenResponse) throw new Error('Error getting access permissions.')

    const {
        firstName: name,
        lastName: lastname,
        email,
        displayName,
        photoUrl: urlImage,
        emailVerified: isVerified,
    } = _tokenResponse
    const idToken = await user.getIdToken()
    const response = await postAuth({ idToken, captcha_token })
    const token = response['access-token'] ?? null

    return {
        token,
        email,
        name,
        lastname,
        displayName,
        urlImage,
        isVerified,
    }
}

export class Firebase {
    static sendPasswordResetEmail = (email: string) => sendPasswordResetEmail(auth, email)

    static sendEmailVerification = (...params: Parameters<typeof sendEmailVerification>) =>
        sendEmailVerification(...params)

    static getUserByEmail = async (email: string) => {
        const user = await auth.currentUser
        console.log(user)
        return user
    }
}

interface UserCredentialTokenResponse {
    federatedId: string
    providerId: string
    email: string
    emailVerified: boolean
    firstName: string
    fullName: string
    lastName: string
    photoUrl: string
    localId: string
    displayName: string
    idToken: string
    context: string
    oauthAccessToken: string
    oauthExpireIn: number
    refreshToken: string
    expiresIn: string
    oauthIdToken: string
    rawUserInfo: string
    kind: string
}
