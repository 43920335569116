import { getUserInfo } from '../../infrastructure/api-activation'
import { useIntercom } from '../useIntercom'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useToken } from '../useToken'
import { useUIContext } from '../../context/UIContext'

export const useUser = () => {
    const { showError } = useUIContext()
    const { _id, token } = useToken()
    const { clientUpdate } = useIntercom()
    const navigate = useNavigate()

    const queryKey = ['User', _id ?? 'Unknown']

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            try {
                const data = await getUserInfo()

                const user = data?.user
                const name = user?.displayName ?? `${user?.name} ${user?.lastname}`
                clientUpdate({
                    user_id: user.userId,
                    name,
                    avatar: {
                        type: 'avatar',
                        image_url: user.urlImage ?? '',
                    },
                })
                return data
            } catch (error) {
                showError(error)
            }
        },
        enabled: !!_id,
        refetchOnWindowFocus: false,
    })

    const userLogout = () => navigate('/login/close')

    return {
        ...(data ?? {}),
        displayNameOrName: data?.user.displayNameOrName,
        name: data?.user.name,
        token,
        userLogout,
        isLogged: !!data?.user,
        ...rest,
    }
}
