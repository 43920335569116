import styled from 'styled-components'
import { CSSProperties, ReactNode } from 'react'
import { defaultTheme } from '../styles/themes/defaultTheme'

type props = {
    header?: ReactNode
    main?: ReactNode
    footer?: ReactNode
    style?: CSSProperties
    className?: string
}

export const CardGeneral = ({
    header = null,
    main = null,
    footer = null,
    style,
    className,
}: props) => {
    return (
        <CardStyled style={style} className={className}>
            <HeaderStyle>{header}</HeaderStyle>
            <MainStyle>{main}</MainStyle>
            {footer && <footer>{footer}</footer>}
        </CardStyled>
    )
}

export const CardGeneralSkeleton = ({ style, className }: props) => (
    <CardGeneral
        style={style}
        className={className}
        header={null}
        main={
            <>
                <div className="title skeleton primary" style={{ height: 52, width: '70%' }}></div>
                <div className="subtitle"></div>
            </>
        }
        footer={
            <>
                <div />
                <button
                    className="primary skeleton"
                    style={{ height: '44px', border: '0' }}
                ></button>
            </>
        }
    />
)

const CardStyled = styled.article`
    display: grid;
    grid-template-rows: [header] auto [main] 1fr [footer] max-content;
    box-sizing: border-box;
    background-color: ${defaultTheme.color.primaryOpacity};
    padding: 2em;
    color: white;
    min-height: 320px;
    border-radius: 16px;
    * > {
        box-sizing: border-box;
        margin: 0;
    }
    &.card-general {
        width: 49%;
        min-height: 240px;
    }

    @media screen and (max-width: 768px) {
        padding: 3em;
        &.card-general {
            min-height: 150px;
            width: 100% !important;
            gap: 0;
        }
    }

    footer {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        -webkit-box-pack: justify;
        gap: 1em;
        place-content: center;
        border-top: 1px solid rgba(255, 255, 255, 0.098);
        padding-top: 2em;
        justify-content: space-between;
        min-height: 44px;

        & > div {
            display: grid;
            place-items: center;
        }

        & > div:first-child {
            justify-content: start;
        }

        @media screen and (max-width: 768px) {
            grid-auto-flow: row;
            grid-auto-columns: 1fr;
            text-align: center;
            gap: 0;
            padding-top: 1em;
            & > .centered {
                display: grid;
                justify-content: normal;
            }
            & > button.primary {
                font-size: 12px;
                height: 32px;
                display: grid;
                gap: 0.5em;
                & > svg {
                    font-size: 18px;
                }
            }
            & > div > button.link.white {
                text-align: left;
            }
        }
    }
`

const HeaderStyle = styled.header``

const MainStyle = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
        font-size: 1.3em;
        font-weight: 700;
        line-height: 1.43em;
    }
    & section > div > div {
        overflow: unset;
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 1em;
        & > .title {
            font-size: 1.5rem;
        }
        & > div > * {
            font-size: 0.9rem;
        }
    }
`
