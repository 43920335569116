import styled from 'styled-components'

export const FeatBlock = styled.div`
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 12px;
    padding: 30px;
    margin: 30px 0;
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        color: #073045;
    }
    a {
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        line-height: 26px;
        letter-spacing: 0px;
        color: #073045;
    }
`
