import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from '../../../../components/Icon'
import { defaultTheme } from '../../../../styles/themes/defaultTheme'
import { EcosystemCourseDTO } from '../../../marketplace/api-marketplace'

export const CourseCard = ({ id, title, tags, description, slug, lang }: EcosystemCourseDTO) => {
    const { t } = useTranslation('enroll')
    const navigate = useNavigate()

    const __html = description ?? ''

    return (
        <StyledCard onClick={() => navigate(`/way/course/${slug ?? id}`)}>
            <div className="top-tags"></div>
            <h1 className="title">{t(title)}</h1>
            <div className="tags">
                <img src={`/images/flag.${lang}.svg`} alt={t(lang)} />
                {tags?.map(({ name, color }, index) => (
                    <Tag key={index + name} color={color}>
                        {name}
                    </Tag>
                ))}
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html }}></div>
            <footer>
                <button className="text">
                    {t('See more')} <Icon icon={['fas', 'arrow-right']} />
                </button>
            </footer>
        </StyledCard>
    )
}

export const CourseCardSkeleton = () => {
    return (
        <StyledCard>
            <div className="top-tags"></div>
            <h1 className="title skeleton"> </h1>
            <div className="tags"></div>
            <div className="description skeleton"></div>
            <footer>
                <button className="text skeleton"></button>
            </footer>
        </StyledCard>
    )
}

const StyledCard = styled.article`
    display: grid;
    grid-template-rows: 32px 66px auto 1fr 45px;
    gap: 0.5em;
    max-width: 350px;
    height: 400px;
    background-color: white;
    padding: 2em;
    padding-top: 0.9em;
    border: 4px solid transparent;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        border: 1px solid ${defaultTheme.color.lightGray};
    }

    &.disable {
        opacity: 0.7;
    }

    .top-tags {
        min-height: 30px;
        display: flex;
        justify-content: flex-end;

        & > *:not(:last-child) {
            margin-right: 10px;
        }
    }

    .title {
        display: flex;
        min-height: 66px;
        align-items: center;
        margin: 0;
        font-size: 1.6em;
        letter-spacing: -0.44px;
        line-height: inherit;
        text-transform: capitalize;
    }

    .tags {
        display: flex;
        align-items: center;
        gap: 0.5em;
        min-height: 24px;
        margin-bottom: 0.6em;
        > * {
            width: fit-content;
        }
        img {
            width: 10%;
            border-radius: 4px;
            margin: 3px 0;
        }
    }

    .description {
        font: normal normal normal 14px/22px Poppins;
        color: #18495f80;
        text-align: left;

        // wrap text in 6 lines
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;

        label {
            display: inline-block;
            color: white;
            font-weight: bold;
            background-color: #00a3ff;
            border-radius: 6px;
            padding: 3px 10px;
            font-size: 0.86em;

            &:not(:first-child) {
                margin-left: 5px;
            }
        }
    }

    footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &:hover {
        border-color: #0037504d;
    }
`

export const Tag = styled.div<{ color?: string }>`
    display: inline-block;
    color: white;
    font-weight: bold;
    background-color: ${({ color }) => color ?? 'rgb(0, 163, 255)'};
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 0.86em;
`

/* 
const FreeTag = styled.span`
    border: 1px solid ${defaultTheme.color.primaryOpacity}33;
    border-radius: 8px;
    font-size: small;
    display: grid;
    place-content: center;
    padding: 0 1em;
` 
*/
