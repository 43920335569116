import { Dispatch, ReactNode, SetStateAction, Suspense, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '../../components/Title'
import { Icon } from '../../components/Icon'
import { useUser } from '../../hooks/queries/useUser'
import { useLanguages } from '../../hooks/useLanguages'
import { useEcosystem } from '../marketplace/hooks/useEcosystem'
import { CourseCard, CourseCardSkeleton } from '../Home/component/component/CourseCard'
import styled from 'styled-components'
import { EcosystemCourseDTO, Tag } from '../marketplace/api-marketplace'

export const Ecosystem = () => {
    const { t } = useTranslation('ecosystem')

    const { user } = useUser()
    const { userLang } = useLanguages()
    const [lang, setLang] = useState<string | undefined>(userLang.resolvedLanguage)

    const { ecosystem, allTags, isFetching } = useEcosystem({ lang })

    const [filterByTags, setFilterByTags] = useState<string[]>([])
    const filterByTitleState = useState('')
    const [filterByTitle] = filterByTitleState

    const filterByTagsFn = useCallback(
        ({ tags }: { tags: Tag[] }) =>
            !filterByTags?.length ? true : tags.some(tag => filterByTags.includes(tag.name)),
        [filterByTags]
    )
    const filterByTitleFn = useCallback(
        ({ title, description }: { title: string; description: string }) =>
            !!filterByTitle
                ? title.toLowerCase().includes(filterByTitle.toLowerCase()) ||
                  description.toLowerCase().includes(filterByTitle.toLowerCase())
                : true,
        [filterByTitle]
    )

    if (!user || user?.hideMarketplace) return null

    const filteredCourses = ecosystem?.courses.filter(filterByTagsFn).filter(filterByTitleFn)
    const onlyCareers = filteredCourses?.filter(
        ({ title }) =>
            title.toLowerCase().startsWith('career') || title.toLowerCase().startsWith('carrera')
    )
    const onlyCourses = filteredCourses?.filter(
        ({ title }) =>
            !(title.toLowerCase().startsWith('career') || title.toLowerCase().startsWith('carrera'))
    )

    const filteredTags = Array.from(
        filteredCourses
            ? new Set(
                  filteredCourses
                      .map(({ tags }) => tags)
                      .flat()
                      .map(({ name }) => name)
                      .sort()
              )
            : []
    )

    const LanguageSelector = memo(() => {
        return (
            <button
                className="text"
                onClick={() => setLang(prev => (!prev ? userLang.resolvedLanguage : undefined))}
                disabled={isFetching}
            >
                {!lang ? t('In any language') : t('In {{ lang }}', { lang: userLang.nativeName })}
                <Icon icon={['far', 'language']} />
            </button>
        )
    })

    return (
        <EcosystemStyles>
            <Title title={t('Ecosystem')} subTitle="" size="large">
                <SearchBar filterByTitleState={filterByTitleState} />
            </Title>

            <br />

            <div className="ecosystems-buttons">
                {allTags.map(label => (
                    <button
                        key={label}
                        disabled={!filteredTags.includes(label)}
                        className={filterByTags.includes(label) ? 'primary' : 'outlined'}
                        onClick={() =>
                            setFilterByTags(prev =>
                                prev.includes(label)
                                    ? prev.filter(item => item !== label)
                                    : [...prev, label]
                            )
                        }
                    >
                        {label}
                    </button>
                ))}
            </div>
            <br />
            <br />

            {!!onlyCareers?.length && (
                <>
                    <Section
                        title="Careers"
                        subtitle={{
                            whileFetching: 'We are looking for the best career for you...',
                            afterFetching: 'Find the career that best suits you.',
                        }}
                        filter={filterByTags}
                        isFetching={isFetching}
                        courses={onlyCareers}
                        titleRight={<LanguageSelector />}
                    />

                    <br />
                </>
            )}

            <Section
                title="Courses"
                subtitle={{
                    whileFetching: 'We are looking for the best courses for you...',
                    afterFetching: 'Consult our courses and personalize your learning.',
                }}
                filter={filterByTags}
                isFetching={isFetching}
                courses={onlyCourses}
                titleRight={<LanguageSelector />}
            />
        </EcosystemStyles>
    )
}

const Section = ({
    courses,
    title,
    subtitle,
    filter,
    titleRight,
    isFetching,
}: {
    courses?: EcosystemCourseDTO[]
    title: string
    subtitle: {
        whileFetching: string
        afterFetching: string
    }
    filter?: string[]
    titleRight?: ReactNode
    isFetching: boolean
}) => {
    const { t } = useTranslation('home')
    return (
        <>
            <Title
                title={t(title)}
                subTitle={t(isFetching ? subtitle.whileFetching : subtitle.afterFetching)}
            >
                {titleRight}
            </Title>
            {courses && !courses.length && <p>{t('There are no courses available.')}</p>}
            <br />

            <Grid>
                <Suspense fallback={<Skeleton />}>
                    {isFetching ? (
                        <Skeleton />
                    ) : (
                        courses
                            ?.filter(({ tags }) =>
                                !filter?.length ? true : tags.some(tag => filter.includes(tag.name))
                            )
                            .map((network, idx) => (
                                <CourseCard key={`${idx}_${network.slug}`} {...network} />
                            ))
                    )}
                </Suspense>
            </Grid>
        </>
    )
}

const Grid = styled.div`
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const Skeleton = () => (
    <>
        <CourseCardSkeleton />
        <CourseCardSkeleton />
        <CourseCardSkeleton />
    </>
)

const SearchBar = ({
    filterByTitleState,
}: {
    filterByTitleState: [string, Dispatch<SetStateAction<string>>]
}) => {
    const { t } = useTranslation('ecosystem')
    const [isSearching, setIsSearching] = useState(false)
    const [filterByTitle, setFilterByTitle] = filterByTitleState

    return (
        <div
            className="search"
            style={{
                display: 'flex',
                alignItems: 'center',
                height: 55,
            }}
        >
            {isSearching || !!filterByTitle ? (
                <>
                    <input
                        type="text"
                        placeholder={t('Search by course')}
                        value={filterByTitle}
                        onChange={({ target: { value } }) => setFilterByTitle(value)}
                        style={{
                            marginRight: '-3em',
                            paddingRight: '3em',
                        }}
                    />
                    <button className="icon outlined">
                        <Icon
                            icon={['far', 'times']}
                            size="lg"
                            onClick={() => setFilterByTitle('')}
                        />
                    </button>
                </>
            ) : (
                <button className="icon outlined">
                    <Icon
                        icon={['far', 'magnifying-glass']}
                        size="lg"
                        onClick={() => setIsSearching(prev => !prev)}
                    />
                </button>
            )}
        </div>
    )
}

const EcosystemStyles = styled.section`
    & > .ecosystems-buttons {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        & > button {
            margin: 0 !important;
            @media screen and (max-width: 340px) {
                max-width: min-content;
            }
        }
    }
`
