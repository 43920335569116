import { FormEvent, useEffect, useState } from 'react'
import { GACategory, SendGAEvent } from '../../../helpers/GAEvents'
import { useNavigate, useSearchParams } from 'react-router-dom'

import DayJS from '../../../helpers/DayJS'
import { sendCodeToEmail } from '../../../infrastructure/api-widget'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { validateCredentials } from '../../../infrastructure/api-activation'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Input } from '../../../components/FormElements/Input'

const resendSecondsLimit = 60 * 5

export const LoginWithCode = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { t } = useTranslation('signIn')

    let [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [code, setCode] = useState('')
    const [error] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()

    const email = searchParams.get('email')
    const isValidCode = code.length === 6
    const storageKey = `${email} expiration code`
    const storageDate = localStorage.getItem(storageKey)
    const now = DayJS()
    const dateOfLastEmail = DayJS(storageDate ?? undefined)
    const isSameOrAfter = now.isSameOrAfter(dateOfLastEmail)
    const canSend = now.isSameOrAfter(dateOfLastEmail)
    const timeToResend = DayJS.duration(!isSameOrAfter ? dateOfLastEmail.diff(now) : 0).format(
        'mm:ss'
    )

    // Refresh component every second
    const [, setCount] = useState(0)
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCount(prev => prev + 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])
    // Refresh component every second

    const invalidCodeMessage = t('The code entered is invalid.')

    const onSubmit = async (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (!executeRecaptcha) return
        code &&
            validateCredentials({
                code,
                captcha_token: await executeRecaptcha('validateCredentials'),
            })
                .then(() => navigate('/redirect'))
                .catch(() => setErrorMessage(invalidCodeMessage))
    }

    const { refetch } = useQuery({
        queryKey: ['Captcha'],
        queryFn: async () => {
            try {
                if (executeRecaptcha) {
                    const data = await sendCodeToEmail(
                        `${email}`,
                        await executeRecaptcha('sendCodeToEmail')
                    )
                    if (data?.code) {
                        navigator?.clipboard?.writeText(data.code)
                        setCode(data.code)
                    }
                    const nextExpireCodeDate = DayJS()
                        .add(resendSecondsLimit, 'seconds')
                        .toISOString()
                    localStorage.setItem(storageKey, nextExpireCodeDate)
                    return data
                }
            } catch (error) {
                const errorMessage = t('Error trying to send verification code to {{ email }}.', {
                    email,
                })
                SendGAEvent(GACategory.ERROR, 'Error trying to send verification code')
                setErrorMessage(errorMessage)
                localStorage.removeItem(storageKey)
            }
        },
        retry: false,
        enabled: !storageDate && !!executeRecaptcha,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        error && SendGAEvent(GACategory.ERROR, error)
    }, [error])

    return (
        <>
            <form onSubmit={onSubmit}>
                <h1 className="title">{t('Validate your code')}</h1>
                <p className="light center">
                    {t(
                        'To log in, enter the 6-digit code you received in your email inbox. (Check your spam)'
                    )}
                </p>

                <input type="hidden" name="email" defaultValue={email ?? ''} />

                <Input
                    {...{
                        label: t('Code'),
                        id: 'code',
                        name: 'code',
                        type: 'tel',
                        value: code,
                        onChange: ({ target: { value } }) => setCode(value),
                        error: errorMessage,
                    }}
                />

                <br />

                <button
                    className="link"
                    onClick={() => refetch()}
                    style={{ textAlign: 'center' }}
                    children={
                        canSend
                            ? t('Resend validation code to {{ email }}', { email })
                            : t(`You can send a new code in {{ timeToResend }} minutes.`, {
                                  timeToResend,
                              })
                    }
                    disabled={!canSend}
                />

                <br />
                <br />
                <div className="buttons column">
                    <button
                        type="submit"
                        className="primary"
                        children={t('Log in')}
                        disabled={!isValidCode}
                    />
                    <button
                        className="text"
                        children={t(`Cancel`)}
                        onClick={() => navigate('/login')}
                    />
                </div>
            </form>
        </>
    )
}

export default LoginWithCode
