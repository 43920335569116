import styled from 'styled-components'

export const StaticInfoBlock = styled.div`
    padding: 30px 0;
    div.block-content {
        display: flex;
        div.icon-container {
            margin-right: 18px;
            padding: 2px 0 0;
            .icon {
                font-size: 20px;
            }
        }
        div.info-container {
            width: 100%;
            img,
            video {
                width: 100%;
                margin: 34px 0px 10px;
            }
            .yt-lite {
                margin-top: 30px;
                border-radius: 12px;
                aspect-ratio: 56% !important;
                --aspect-ratio: 56% !important;
            }
            h4 {
                color: #1f394c;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                margin: 0;
            }
            h5 {
                font-size: 17px;
                font-weight: 700;
            }
            p {
                color: #073045;
                font-weight: 400;
                font-size: 15px;
                line-height: 26px;
                letter-spacing: 0px;
            }
            a {
                text-decoration: none;
                font-weight: bold;
                font-size: 15px;
                line-height: 23px;
                letter-spacing: 0px;
                color: #073045;
            }
        }
    }
    &:not(:last-child) {
        border-bottom: 1px solid #eaeaea;
    }
`
