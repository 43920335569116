import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '../../../components/FormElements/Input'
import { useLocalization } from '../../../hooks/queries/useLocalization'
import { useCountries } from '../../../hooks/queries/useCountries'
import LoadingPage from '../../static/LoadingPage'
import { PhoneNumber, isValidPhone } from '../../../components/FormElements/PhoneNumber'
import { DefaultUserDTO } from '../../../interfaces/api-activation'
import { Country } from '../../../components/FormElements/Country'
import { useProfile } from '../../profile/hooks/useProfile'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { SignUpStyle } from './SignUpStep1'

export const SignUpStep2 = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { t } = useTranslation('signUp')
    const { localization } = useLocalization()
    const { countries } = useCountries()
    const { user, updateAsync, isLoading, api_error, isUpdating } = useProfile()
    const [changes, setChanges] = useState<Partial<DefaultUserDTO & typeof user>>({})
    const { countryId, name, lastname, phone, referrer, dni } = changes ?? {}

    const DNIRequiredCountries = ['Uruguay']

    const isDNIRequired = countries?.some(
        ({ name, id }) => DNIRequiredCountries.includes(name) && id === (countryId ?? 0)
    )

    useEffect(() => {
        if (!user) return
        user && setChanges(user)
    }, [user])

    // Set user country
    useEffect(() => {
        if (countryId) return
        const countryIdLocalization =
            countries?.find(({ iso3 }) => iso3 === localization?.country_code_iso3)?.id ?? ''
        countryIdLocalization &&
            setChanges(prev =>
                prev
                    ? {
                          ...prev,
                          countryId: countryIdLocalization,
                      }
                    : prev
            )
    }, [countryId, countries, localization?.country_code_iso3, localization?.country_code])
    // Set user country

    const onChange = ({
        target: { name, value },
    }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) =>
        setChanges(prev => ({ ...prev, [name]: name === 'countryId' ? +value : value }))

    const isCompleted =
        !!countryId && !!name && !!lastname && !!phone && (isDNIRequired ? !!dni : true)

    const onSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (!isCompleted) return
        await updateAsync(changes)
        await queryClient.invalidateQueries({ queryKey: ['User'], exact: false })
        navigate('/')
    }

    if (isLoading || isUpdating) return <LoadingPage />

    return (
        <SignUpStyle>
            <strong className="blue">
                {t('Step {{ step }} of {{ steps }}', {
                    step: 2,
                    steps: 2,
                })}
            </strong>
            <h1 className="title">{t('Personal information')}</h1>
            <p className="light">
                {t(
                    'Now you are part of the network that is promoting your professional development through cooperation.'
                )}
            </p>
            <br />

            <form onSubmit={onSubmit}>
                <Input
                    {...{
                        label: t('Name'),
                        id: 'name',
                        name: 'name',
                        type: 'text',
                        autoComplete: 'given-name',
                        value: name ?? '',
                        onChange,
                        api_error,
                    }}
                />
                <br />

                <Input
                    {...{
                        label: t('Surname'),
                        id: 'lastname',
                        name: 'lastname',
                        type: 'text',
                        autoComplete: 'family-name',
                        value: lastname ?? '',
                        onChange,
                        api_error,
                    }}
                />
                <br />

                <Country
                    {...{
                        value: countryId ?? '',
                        onChange,
                        api_error,
                    }}
                />
                {isDNIRequired && (
                    <>
                        <br />
                        <Input
                            {...{
                                label: t('C.I.'),
                                id: 'dni',
                                name: 'dni',
                                type: 'number',
                                value: dni ?? '',
                                onChange,
                                disabled: !!user?.dni,
                                api_error,
                                maxLength: 10,
                                error: dni && dni.length > 10 ? t('Invalid C.I.') : undefined,
                            }}
                        />
                    </>
                )}
                <br />
                <PhoneNumber
                    {...{
                        label: t('What is your phone number?'),
                        value: (phone as any) ?? undefined,
                        onChange: (phone: any) =>
                            setChanges(prev => (prev ? { ...prev, phone: phone ?? null } : prev)),
                        error:
                            phone && !isValidPhone(phone ?? '')
                                ? t('Invalid phone number')
                                : undefined,
                    }}
                />
                <br />
                <Input
                    {...{
                        label: t('Do you have any code?'),
                        id: 'referrer',
                        name: 'referrer',
                        type: 'text',
                        autoComplete: 'off',
                        value: referrer ?? '',
                        onChange,
                        disabled: !!user?.referrer,
                        api_error,
                    }}
                />
                <br />
                <button
                    className="primary"
                    children={t('Continue')}
                    style={{ width: '100%', margin: '0 auto' }}
                    disabled={!isCompleted}
                />
            </form>
        </SignUpStyle>
    )
}
