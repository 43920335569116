import styled from 'styled-components'
import { GoToCourseButton } from './GoToCourseButton'
import { MeetButton } from './MeetButton'
import { UserCourseDTOExtended } from '../../interfaces/api-activation'
import { useTranslation } from 'react-i18next'

export const AssignationBar = (props: Props) => (
    <>
        <Left {...props} />
        <Right {...props} />
    </>
)

const Left = ({ status, asyncCourse }: Props) => {
    const { t } = useTranslation('dashboard')

    if (asyncCourse) return <div />

    return <div>{status === 'finished' ? t('Your course has ended.') : null}</div>
}

const Right = (props: Props) => (
    <RightSection className="right centered">
        <GoToCourseButton {...props} />
        <MeetButton {...props} />
    </RightSection>
)

const RightSection = styled.section`
    gap: 1.5em;
    flex-wrap: wrap;

    & > button + button {
        margin-left: unset !important;
    }
`

type Props = UserCourseDTOExtended & { isInside: boolean }
