import DayJS from '../../helpers/DayJS'
import styled, { css } from 'styled-components'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Section as SectionType, useStudyPlan } from '../../pages/course/hooks/useStudyPlan'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { capitalize } from '../../helpers/formatters'

export const ContentCard = ({
    content,
    status,
    icon,
    isTodaySection,
    sectionRef,
    openTodaySection,
    setOpenTodaySection,
    activeSectionId,
    enableDuration = true,
    navigationButtons,
    isInsideContent = false,
}: ContentCardProps) => {
    const { courseId, stepId } = useParams()
    const navigate = useNavigate()
    const { todayContent } = useStudyPlan(courseId)
    const { currentCourse } = useUserCourses()
    const { nextClass } = currentCourse || {}
    const [openContent, setOpenContent] = useState(false)
    const etc = nextClass?.duration
    const [amount, unit] = etc || []

    const isAbble = status === 'done' || status === 'inProgress'
    const isDone = status === 'done'
    const isBlock = status === 'blocked'
    const isTodaySectionInProgress = isTodaySection && !isDone

    const iconToUse: IconProp = isDone
        ? ['fas', 'circle-check']
        : isAbble
        ? ['fas', 'circle-arrow-right']
        : ['fas', 'lock']

    const iconStyle = {
        color: isDone
            ? defaultTheme.color.greenHarmony
            : isAbble
            ? defaultTheme.color.electricBlue
            : defaultTheme.color.gray,
    }

    useEffect(() => {
        !isInsideContent && openTodaySection === content._id && setOpenContent(true)
        setOpenTodaySection && setOpenTodaySection('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openTodaySection, isInsideContent])

    useEffect(() => {
        if (!isInsideContent) {
            setOpenContent(activeSectionId === content._id)
            setTimeout(() => {
                const sectionElement = document.getElementById(activeSectionId!)
                sectionElement?.scrollIntoView({ block: 'center' })
            }, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSectionId, isInsideContent])
    // Step Constants
    const firstStepId = content.steps[0]?._id
    const currentStep = content.steps.find(step => step._id === stepId)
    const currentStepTitle = currentStep?.title
    const cardStepTitle = currentStepTitle ? (
        <>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} className="only-desktop" />
            <p className="section-card-subtitle truncate">{capitalize(currentStepTitle)}</p>
        </>
    ) : (
        ''
    )
    const handleStepClick = (stepId: string) => {
        if (isAbble) navigate(`/content/step/${stepId}`)
        setOpenContent(!openContent)
    }
    return (
        <ContentCardStyled
            {...{ isTodaySectionInProgress }}
            ref={content._id === todayContent.section?._id ? sectionRef : null}
            className={`${openContent ? 'open' : ''} border-radius`}
        >
            <article
                className={`section-card border-radius ${openContent ? 'isOpen' : ''} ${
                    isBlock ? 'disabled' : ''
                }`}
                onClick={() => setOpenContent(!openContent)}
            >
                <div className="section-card-left-item display-flex">
                    <FontAwesomeIcon icon={icon ?? iconToUse} style={iconStyle} className="icon" />
                </div>
                <div
                    className="section-card-title display-flex"
                    onClick={isInsideContent ? undefined : () => handleStepClick(firstStepId)}
                >
                    <h4 className={`truncate ${isBlock ? '' : 'underline-hover'}`}>
                        {capitalize(content.title)}
                    </h4>
                    {cardStepTitle}
                </div>
                <div className="section-card-items">
                    {navigationButtons}
                    {enableDuration && amount && (
                        <div className="section-card-duration">
                            <FontAwesomeIcon icon={['fal', 'clock']} className="icon" />
                            <p>{DayJS.duration(amount, unit).hours()} hs</p>
                        </div>
                    )}
                </div>
                <div className="section-card-right-item display-flex only-desktop">
                    <FontAwesomeIcon
                        style={{ fontSize: '22px' }}
                        icon={
                            isBlock
                                ? ['fal', 'folder']
                                : openContent
                                ? ['fas', 'folder-open']
                                : ['fas', 'folder']
                        }
                    />
                </div>
            </article>
            {isAbble && !!content.steps.length && openContent && (
                <Sections>
                    <Section id={content._id} key={content._id}>
                        <Steps>
                            {content.steps.map(
                                (step, idx) =>
                                    step?._id && (
                                        <Step
                                            id={step._id}
                                            key={idx + content._id + content?._id + step?.title}
                                            onClick={() => handleStepClick(step?._id)}
                                            className="steps-padding"
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                size="sm"
                                                style={{ ...iconStyle, position: 'relative' }}
                                                className="steps-padding step-icon"
                                            />
                                            <StepTitle
                                                abble={isAbble}
                                                active={step?._id === todayContent?.step?._id}
                                                title={step?.title}
                                                className="steps-padding"
                                                style={
                                                    idx !== content.steps.length - 1
                                                        ? { borderBottom: '2px solid #f8f8f8' }
                                                        : {}
                                                }
                                            >
                                                <div
                                                    className={`steps-title ${
                                                        step._id === stepId ? 'active-step' : ''
                                                    }`}
                                                >
                                                    {step?.title}
                                                </div>
                                            </StepTitle>
                                        </Step>
                                    )
                            )}
                        </Steps>
                    </Section>
                </Sections>
            )}
        </ContentCardStyled>
    )
}

export const getStatus = (section: SectionType, todayContent: SectionType) => {
    if (
        (section._id === todayContent?._id && section.abble && !section.done) ||
        (section.abble && !section.done)
    )
        return 'inProgress'
    if (section.done) return 'done'
    return 'blocked'
}

const ContentCardStyled = styled.section<{ isTodaySectionInProgress?: boolean }>`
    background: white;
    color: ${defaultTheme.color.primary};
    border: ${({ isTodaySectionInProgress }) =>
        isTodaySectionInProgress ? '2px solid rgb(0, 55, 80)' : 'none'};
    & .icon {
        margin-right: 0;
    }
    & > .section-card {
        width: 100%;
        min-height: 70px;
        padding: 1em 2em;
        display: grid;
        grid-template-areas: 'leftItem title items rightItem';
        grid-template-columns: [leftItem] auto [title] 1fr [items] auto [rightItem] auto;
        grid-column-gap: 2em;
        align-items: center;
        & > .section-card-left-item {
            grid-area: leftItem;
        }
        & > .section-card-title {
            grid-area: title;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            gap: 0.8em;
            max-width: fit-content;
            .section-card-subtitle {
                font-size: 1.14em;
                color: ${defaultTheme.color.electricBlue};
                margin: 0;
            }
            & > h4 {
                font-size: 1.14em;
                font-weight: 600;
                margin: 0;
            }
            & > h4,
            & > .section-card-subtitle {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                row-gap: 0;
                font-size: 14px !important;
                & > h4,
                p {
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0;
                }
                .icon {
                    font-size: 14px;
                }
            }
        }
        & > .section-card-items {
            grid-area: items;
            & > .section-card-duration {
                display: flex;
                align-items: center;
                gap: 0.5em;
                & > .icon {
                    font-size: 13px;
                }
                & > p {
                    margin: 0;
                }
            }
        }
        & > .section-card-right-item {
            grid-area: rightItem;
        }

        @media screen and (max-width: 768px) {
            grid-template-areas: 'leftItem title items';
            grid-template-columns: auto 1fr auto;
            column-gap: 1em;
            row-gap: 2em;
            & > .section-card-items {
                & > nav {
                    justify-content: flex-start;
                }
            }
        }
    }
    & > .disabled {
        color: ${defaultTheme.color.gray};
    }
    .section-card:not(.disabled):not(.isOpen):hover {
        background: ${defaultTheme.color.lightRedGray};
        border-radius: 10px;
    }
    .section-card:not(.disabled):hover {
        cursor: pointer;
    }
`
const Sections = styled.div`
    padding: 0 2em 0em 2.3em;
`

const Section = styled.div``

const Steps = styled.div`
    .steps-padding,
    .steps-padding:last-child {
        padding-bottom: 1em;
    }
`

const Step = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
`
const StepTitle = styled.div<StepTitleProps>(({ abble, active }) => {
    return css`
        width: 100%;

        .steps-title {
            width: 100%;
            cursor: ${abble ? 'pointer' : 'not-allowed'};
            color: ${abble ? (active ? '#ffffff' : 'inherit') : '#a5a6a8'};
            background: ${active ? '#00A3FF' : 'transparent'};
            flex: 1;
            font-size: 1em;
            font-weight: 400;
            line-height: 20px;
            position: relative;
            transition: background 0.3s;
            border-radius: 6px;
            padding: 0.6em 1em;
        }
        .active-step {
            background: ${defaultTheme.color.blueLight};
            color: ${defaultTheme.color.electricBlue};
            font-weight: 600;
        }
        ${() =>
            abble &&
            css`
                .steps-title:hover {
                    background: ${active ? '#00A3FF' : '#eaeaea'};
                    color: ${active ? defaultTheme.color.white : 'inherit'};
                }
            `}
    `
})

type ContentCardProps = {
    content: SectionType
    status: 'done' | 'inProgress' | 'blocked'
    icon?: IconProp
    isTodaySection?: boolean
    sectionRef?: React.RefObject<HTMLDivElement>
    openTodaySection?: string
    setOpenTodaySection?: (sectionId: string) => void
    activeSectionId?: string
    enableDuration?: boolean
    navigationButtons?: ReactNode
    isInsideContent?: boolean
}

interface StepTitleProps {
    abble: boolean
    done?: boolean
    block?: boolean
    active?: boolean
}
