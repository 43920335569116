import { Content, Tip, TooltipWrapper } from './Tooltip.styles'
import React, { HTMLAttributes } from 'react'

type Placement =
    | 'rightCenter'
    | 'bottomCenter'
    | 'bottomEnd'
    | 'bottomStart'
    | 'topCenter'
    | 'topEnd'
    | 'topStart'

export interface ITooltipProps extends HTMLAttributes<HTMLSpanElement> {
    placement: Placement
    text: string
}

const Tooltip = ({ children, ...props }: ITooltipProps) => {
    return (
        <TooltipWrapper>
            <Tip {...props}>
                <Content>{children}</Content>
            </Tip>
        </TooltipWrapper>
    )
}

export default Tooltip

Tooltip.defaultProps = {
    children: 'Hover me',
    placement: 'rightCenter',
    text: 'This is a tooltip',
}
