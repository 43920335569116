import { useTranslation } from 'react-i18next'

export const useIntercom = () => {
    const { t } = useTranslation('intercom')

    const Intercom = window?.Intercom ?? null

    const newMessage = (message?: string) => Intercom?.('showNewMessage', message)

    const chatWithSales = (message?: string) =>
        Intercom?.(
            'showNewMessage',
            message ?? t('Hello!\n\nI want to chat with a consultant if possible please.')
        )

    const chatWithSupport = (message?: string) =>
        Intercom?.('showNewMessage', message ?? t('Hello!\n\nCan you help me please.'))

    const clientUpdate = (settings: Intercom_.IntercomSettings) => Intercom?.('update', settings)

    const clientBoot = (settings: Intercom_.IntercomSettings) => Intercom?.('boot', settings)

    const openChat = () => Intercom('show')

    const trackEvent = (...params: TrackEventParams) => Intercom?.('trackEvent', ...params)

    return {
        openChat,
        chatWithSales,
        chatWithSupport,
        clientUpdate,
        trackEvent,
        clientBoot,
        newMessage,
    }
}

type TrackEventParams = Parameters<Intercom_.IntercomCommandSignature['trackEvent']>
