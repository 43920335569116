import styled from 'styled-components'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { MutableRefObject, ReactNode } from 'react'

type Props = {
    children: ReactNode
    ref?: MutableRefObject<HTMLDivElement | null>
}

const ModalPageLayout = ({ children, ref }: Props) => (
    <ContentStyles ref={ref} children={children} />
)

export default ModalPageLayout

const ContentStyles = styled.div`
    box-sizing: border-box;
    margin-bottom: 5em;
    padding: 3em 8em;
    border-radius: 16px;
    background: ${props => props.theme.color.white};
    color: ${defaultTheme.color.primary};
    & > * {
        max-width: 100% !important;
    }

    p {
        line-height: initial;
    }

    a {
        text-decoration: underline;
    }

    & > table {
        font-size: 0.9em;
    }
    & code {
        white-space: pre-wrap;
    }

    // Mobile styles
    @media screen and (max-width: 768px) {
        border: 0;
        padding: 2em;
        /* min-height: fit-content; */
        /* background-color: ${defaultTheme.color.offWhite}; */
        h2 {
            font-size: 1.5em;
        }
    }
`
