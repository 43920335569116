import { ComponentProps, type ReactNode } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, {
    isValidPhoneNumber,
    type Props,
    type DefaultInputComponentProps,
    type Country,
} from 'react-phone-number-input'
import { useLocalization } from '../../hooks/queries/useLocalization'
import { useTranslation } from 'react-i18next'

export const PhoneNumber = ({ label, labelProps, error, ...rest }: PhoneNumberProps) => {
    const { t } = useTranslation('signUp')
    const { localization } = useLocalization()
    return (
        <label {...labelProps}>
            {label ?? t('Contact cell phone')}
            <PhoneInput
                defaultCountry={localization?.country_code as CountryCode}
                {...{
                    id: 'phone',
                    name: 'phone',
                    autoComplete: 'tel',
                }}
                {...rest}
                value={(rest.value as ComponentProps<typeof PhoneInput>['value']) ?? undefined}
            />
            <div className="error">{error}</div>
        </label>
    )
}

export type CountryCode = Country

export const isValidPhone = isValidPhoneNumber

interface PhoneNumberProps extends Props<DefaultInputComponentProps> {
    label?: ReactNode
    labelProps?: React.DetailedHTMLProps<
        React.LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
    >
    error?: string
}
