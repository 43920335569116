import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToken } from '../../../hooks/useToken'
import { API_AXIOS_ERROR } from '../../../interfaces/api-activation'
import {
    updateUser as mutationFn,
    getUser as queryFn,
    updateUserImage,
} from '../../register/api-register'

export const useProfile = (options?: Options) => {
    const queryClient = useQueryClient()

    const { _id } = useToken()
    const queryKey = ['User', 'Profile', _id]
    const { data: user, isLoading } = useQuery({
        queryKey,
        queryFn,
        enabled: !!_id,
        ...options,
    })

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isPending: isUpdating,
        error: api_error,
        status: updateStatus,
    } = useMutation({
        mutationFn,
        onMutate: async values => {
            await queryClient.cancelQueries({ queryKey: ['User'], exact: false })
            const snapshot = queryClient.getQueryData<TData>(queryKey)
            queryClient.setQueryData<TData>(queryKey, prev =>
                prev ? { ...prev, ...values } : prev
            )
            return snapshot
        },
        onError: (err: API_AXIOS_ERROR, _, snapshot) =>
            queryClient.setQueryData(queryKey, snapshot),
        onSuccess: values =>
            queryClient.setQueryData<TData>(queryKey, prev =>
                prev ? { ...prev, ...values } : prev
            ),
    })

    const {
        mutate: uploadImage,
        mutateAsync: uploadImageAsync,
        isPending: isImageLoading,
    } = useMutation({
        mutationFn: updateUserImage,
        onMutate: async urlImage => {
            await queryClient.cancelQueries({ queryKey: ['User'], exact: false })
            const snapshot = queryClient.getQueryData<TData>(queryKey)
            queryClient.setQueryData<TData>(queryKey, prev => (prev ? { ...prev, urlImage } : prev))
            return snapshot
        },
        onError: (err: API_AXIOS_ERROR, _, snapshot) =>
            queryClient.setQueryData(queryKey, snapshot),
    })

    const displayNameOrName = !user ? '' : user?.displayName ?? `${user?.name} ${user?.lastname}`

    return {
        user,
        displayNameOrName,
        isLoading,
        update,
        updateAsync,
        isUpdating,
        api_error,
        updateStatus,
        uploadImage,
        uploadImageAsync,
        isImageLoading,
    }
}

type TData = Awaited<NonNullable<ReturnType<typeof queryFn>>>
type Options = UseQueryOptions<TData>
