import { library } from '@fortawesome/fontawesome-svg-core'

// Thin icons
import { faCheckCircle as fatCheckCircle } from '@fortawesome/pro-thin-svg-icons'

// Light icons
import {
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
    faArrowRight,
    faArrowRightArrowLeft as falArrowRightArrowLeft,
    faArrowRightFromBracket as falArrowRightFromBracket,
    faBars as falBars,
    faBell as falBell,
    faBolt as falBolt,
    faBrowser as falBrowser,
    faBullseyeArrow as falBullseyeArrow,
    faCalendar as falCalendar,
    faCamera as falCamera,
    faCameraMovie as falCameraMovie,
    faCartShopping as falCartShopping,
    faChartNetwork as falChartNetwork,
    faCheck as falCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faCircle as falCircle,
    faCircleArrowDown as falCircleArrowDown,
    faCircleCheck as falCircleCheck,
    faCircleCheck as fatCircleCheck,
    faCircleInfo as falCircleInfo,
    faCircleLocationArrow as falCircleLocationArrow,
    faCircleNotch,
    faCirclePlay as falCirclePlay,
    faCircleQuestion as falCircleQuestion,
    faCircleQuestion,
    faClipboard as falClipboard,
    faClock as falClock,
    faClock,
    faCode as falCode,
    faCoffee,
    faCoinBlank as falCoinBlank,
    faComments as falComments,
    faCopy as falCopy,
    faCube as falCube,
    faDatabase as falDatabase,
    faDownload as falDownload,
    faDrawCircle as falDrawCircle,
    faEgg as falEgg,
    faFileCertificate as falFileCertificate,
    faFilePdf as falFilePdf,
    faFolder as falFolder,
    faFutbol as falFutbol,
    faGamepadModern as falGamepadModern,
    faGauge as falGauge,
    faGaugeMax as falGaugeMax,
    faGraduationCap as falGraduationCap,
    faGrid2 as falGrid2,
    faHandPaper,
    faHeart as falHeart,
    faHome as falHome,
    faInfoCircle as falInfoCircle,
    faLinkSimple as falLinkSimple,
    faLinkSimple,
    faList as falList,
    faListUl,
    faMailbox as falMailbox,
    faMailbox,
    faMedal as falMedal,
    faMusic as falMusic,
    faNote as falNote,
    faPlane as falPlane,
    faPlayCircle as falPlayCircle,
    faScrewdriverWrench as falScrewdriverWrench,
    faSearch as falSearch,
    faShoePrints as falShoePrints,
    faShoppingBag as falShoppingBag,
    faShoppingCart as falShoppingCart,
    faSpinner,
    faStreetView as falStreetView,
    faThumbsDown as falThumbsDown,
    faTimes as falTimes,
    faTimesCircle,
    faTreePalm as falTreePalm,
    faTrophy as falTrophy,
    faTvRetro as falTvRetro,
    faUser as falUser,
    faUserCheck as falUserCheck,
    faUserCircle,
    faUserClock as falUserClock,
    faUserGroup as falUserGroup,
    faUserHeadset as falUserHeadset,
    faUsers as falUsers,
    faUserSlash as falUserSlash,
    faVideo as falVideo,
} from '@fortawesome/pro-light-svg-icons'

// Regular icons
import {
    faArrowLeft as farArrowLeft,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faBadgeCheck as farBadgeCheck,
    faBallotCheck as farBallotCheck,
    faBolt as farBolt,
    faCalendar as farCalendar,
    faCalendarClock as farCalendarClock,
    faCartShopping as farCartShopping,
    faCirclePlay as farCirclePlay,
    faCoinBlank as farCoinBlank,
    faFile as farFile,
    faFilm as farFilm,
    faGaugeHigh as farGaugeHigh,
    faGaugeLow as farGaugeLow,
    faInfoCircle as farInfoCircle,
    faLanguage as farLanguage,
    faMagnifyingGlass as farMagnifyingGlass,
    faMegaphone as farMegaphone,
    faMessageQuestion as farMessageQuestion,
    faRankingStar as farRankingStar,
    faShoePrints as farShoePrints,
    faTimes as farTimes,
    faPeopleGroup as farPeopleGroup,
    faPenToSquare as farPenToSquare,
    faSpinner as farSpinner,
    faBars as farBars,

} from '@fortawesome/pro-regular-svg-icons'

// Solid icons
import {
    faArrowLeft as fasArrowLeft,
    faArrowRight as fasArrowRight,
    faBell,
    faBolt as fasBolt,
    faCalendarCheck as fasCalendarCheck,
    faCamera as fasCamera,
    faChartNetwork as fasChartNetwork,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCircle as fasCircle,
    faCircleArrowRight as fasCircleArrowRight,
    faCircleCheck as fasCircleCheck,
    faCircleExclamation as fasCircleExclamation,
    faCircleQuestion as fasCircleQuestion,
    faCode as fasCode,
    faComments as fasComments,
    faDownload as fasDownload,
    faDrawCircle as fasDrawCircle,
    faEgg as fasEgg,
    faFileCertificate as fasFileCertificate,
    faFolder as fasFolder,
    faFolderOpen as fasFolderOpen,
    faGraduationCap as fasGraduationCap,
    faHeart as fasHeart,
    faHome as fasHome,
    faInfoCircle as fasInfoCircle,
    faLinkSimple as fasLinkSimple,
    faLockOpen as fasLockOpen,
    faMapPin as fasMapPin,
    faMedal,
    faMessageQuestion as fasMessageQuestion,
    faObjectGroup as fasObjectGroup,
    faPlayCircle as fasPlayCircle,
    faTimes as fasTimes,
    faTrophy,
    faUserCheck as fasUserCheck,
    faUserClock as fasUserClock,
    faUserHeadset as fasUserHeadset,
    faUsers as fasUsers,
    faUsersClass,
    faUserSlash as fasUserSlash,
    faVideo as fasVideo,
    faShoePrints as fasShoePrints,
    faCoinVertical as fasCoinVertical,
    faCircleVideo as fasCircleVideo,
    faCalendarPlus as fasCalendarPlus,
    faLock as fasLock,
    faList as fasList,
} from '@fortawesome/pro-solid-svg-icons'

// Duotone icons
import { faCircle as fadCircle } from '@fortawesome/pro-duotone-svg-icons'

// Brand icons
import {
    faDiscord as fabDiscord,
    faFacebook as fabFacebook,
    faGoogle as fabGoogle,
    faInstagram as fabInstagram,
    faIntercom as fabIntercom,
    faLinkedin as fabLinkedin,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faArrowRight,
    fabDiscord,
    faBell,
    fabFacebook,
    fabGoogle,
    fabInstagram,
    fabIntercom,
    fabLinkedin,
    fabYoutube,
    faCheckCircle,
    faCheckSquare,
    faCircleNotch,
    faCircleQuestion,
    faClock,
    faCoffee,
    fadCircle,
    faHandPaper,
    falArrowLeft,
    falArrowRight,
    falArrowRight,
    falArrowRight,
    falArrowRightArrowLeft,
    falArrowRightFromBracket,
    falBars,
    falBell,
    falBolt,
    falBrowser,
    falBullseyeArrow,
    falCalendar,
    falCamera,
    falCameraMovie,
    falCartShopping,
    falChartNetwork,
    falCheck,
    falChevronLeft,
    falChevronLeft,
    falChevronLeft,
    falChevronRight,
    falChevronRight,
    falChevronRight,
    falCircle,
    falCircleArrowDown,
    falCircleCheck,
    falCircleInfo,
    falCircleLocationArrow,
    falCirclePlay,
    falCircleQuestion,
    falClipboard,
    falClock,
    falCode,
    falCode,
    falCode,
    falCoinBlank,
    falComments,
    falCopy,
    falCube,
    falDatabase,
    falDownload,
    falDownload,
    falDownload,
    falDrawCircle,
    falEgg,
    falEgg,
    falFileCertificate,
    falFileCertificate,
    falFileCertificate,
    farMagnifyingGlass,
    falFilePdf,
    falFolder,
    falFutbol,
    falGamepadModern,
    falGauge,
    falGaugeMax,
    falGraduationCap,
    falGraduationCap,
    falGraduationCap,
    falGrid2,
    falHeart,
    falHome,
    falInfoCircle,
    faLinkSimple,
    faListUl,
    falLinkSimple,
    falLinkSimple,
    falLinkSimple,
    falList,
    falMailbox,
    falMedal,
    falMusic,
    falNote,
    falPlane,
    falPlayCircle,
    falScrewdriverWrench,
    falSearch,
    falShoePrints,
    falShoppingBag,
    falShoppingCart,
    falStreetView,
    falThumbsDown,
    falTimes,
    falTreePalm,
    falTrophy,
    falTvRetro,
    falUser,
    falUserCheck,
    falUserClock,
    falUserGroup,
    falUserHeadset,
    falUsers,
    falUserSlash,
    falVideo,
    faMailbox,
    faMedal,
    farArrowLeft,
    farArrowRightFromBracket,
    farBadgeCheck,
    farBallotCheck,
    farBolt,
    farCalendar,
    farCalendar,
    farCalendarClock,
    farCartShopping,
    farCirclePlay,
    farCoinBlank,
    farFile,
    farFilm,
    farGaugeHigh,
    farGaugeLow,
    farInfoCircle,
    farLanguage,
    farMegaphone,
    farMessageQuestion,
    farPeopleGroup,
    farRankingStar,
    farShoePrints,
    farTimes,
    fasArrowLeft,
    fasArrowRight,
    fasBolt,
    fasCalendarCheck,
    fasCamera,
    fasChartNetwork,
    fasCheck,
    fasCheckCircle,
    fasChevronLeft,
    fasChevronRight,
    fasCircle,
    fasCircleArrowRight,
    fasCircleCheck,
    fasCircleExclamation,
    fasCircleExclamation,
    fasCircleQuestion,
    fasCode,
    fasComments,
    fasDownload,
    fasDrawCircle,
    fasEgg,
    fasFileCertificate,
    fasFolder,
    fasFolderOpen,
    fasGraduationCap,
    fasHeart,
    fasHome,
    fasInfoCircle,
    fasLinkSimple,
    fasLockOpen,
    fasMapPin,
    fasMessageQuestion,
    fasObjectGroup,
    faSpinner,
    fasPlayCircle,
    fasTimes,
    fasUserCheck,
    fasUserClock,
    fasUserHeadset,
    fasUsers,
    fasUserSlash,
    fasVideo,
    fatCheckCircle,
    fatCircleCheck,
    faTimesCircle,
    faTrophy,
    faUserCircle,
    faUsersClass,
    farPenToSquare,
    farSpinner,
    farBars,
    fasCoinVertical,
    fasShoePrints,
    fasCircleVideo,
    fasCalendarPlus,
    fasLock,
    fasList
)
