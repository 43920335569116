import styled from 'styled-components'
import { Title } from '../Type/Title'
import bg from '../../assets/tyc_modal_bg.jpg'
import { PrimaryButton } from '../Buttons/PrimaryBtn'
import { BtnLinkBold } from '../Buttons/BtnLink'
import { useContext, useState } from 'react'
import { CheckBox } from '../FormElements/Checkbox'
import axios from 'axios'
import { UIContext } from '../../context/UIContext'
import { GACategory, SendGAEvent } from '../../helpers/GAEvents'

interface IProps {
    update: 'first-time' | 'update'
}

const TycModal = ({ update }: IProps) => {
    const [checked, setChecked] = useState<boolean>(false)
    const { dispatchModal } = useContext(UIContext)

    const switchChecked = () => {
        setChecked(prev => !prev)
    }

    const accept = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_WIDGET_API}/v1/lxp/signTermsAndConditions`)
            dispatchModal({ type: null })
            SendGAEvent(GACategory.TYC, 'Accept T&C Clicked')
        } catch (error: any) {
            console.log('Error signing Terms and Conditions')
            SendGAEvent(GACategory.ERROR, 'Error Accepting T&C', error.toString())
        }
    }

    const reject = async () => {
        SendGAEvent(GACategory.TYC, 'Accept T&C Clicked')
        dispatchModal({ type: null })
        window.location.href = '/logout'
    }

    return (
        <TycModalStyled>
            <div className="heading"></div>
            <div className="content">
                <Title>
                    {update === 'update'
                        ? 'Hemos actualizado los Términos y Condiciones'
                        : 'Para comenzar, lee y acepta los términos y condiciones'}
                </Title>
                <div className="accept">
                    <CheckBox type="checkbox" checked={checked} onChange={switchChecked} />
                    He leído y acepto los{' '}
                    <a
                        href="https://eggcooperation.com/terminos-y-condiciones/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <strong style={{ marginLeft: '4px' }}>Términos y condiciones</strong>
                    </a>
                </div>
                <div className="btn-container">
                    <PrimaryButton onClick={accept} disabled={!checked}>
                        Aceptar
                    </PrimaryButton>
                    <BtnLinkBold onClick={reject}>No aceptar</BtnLinkBold>
                </div>
            </div>
        </TycModalStyled>
    )
}

export default TycModal

const TycModalStyled = styled.div`
    width: 550px;
    min-height: 550px;
    background-color: white;
    border-radius: 20px;
    border: 1px solid ${props => props.theme.color.gray};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .heading {
        min-height: 245px;
        background: url(${bg});
        background-size: cover;
        background-position: center center;
        border-radius: 20px;
    }
    .content {
        flex: 1;
        padding: 35px 90px;
        text-align: center;
        h1 {
            margin-bottom: 35px;
            padding: 0 40px;
        }
        .accept {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            a {
                text-decoration: none;
                color: ${props => props.theme.color.primary};
                &:hover {
                    text-decoration: underline;
                    color: ${props => props.theme.color.electricBlue};
                }
            }
        }
        .btn-container {
            display: flex;
            flex-direction: column;
        }
    }
`
