export const defaultTheme = {
    color: {
        primary: '#003750',
        darkGray: '#A3A3A3',
        gray: '#C7C7C7',
        lightGray: '#EAEAEA',
        borders: '#EDEDED',
        perl: '#F2F2F2',
        offWhite: '#F8F8F8',
        white: '#FFFFFF',
        green: '#15C1B2',
        greenHarmony: '#15C0AF',
        yellow: '#FFCD00',
        orange: '#FFBA00',
        salmon: '#FF647C',
        purple: '#BF03E2',
        lightBlue: '#62C6FF',
        blue: '#2B75FF',
        darkBlue: '#105DF9',
        blueLight: '#bce6fd',
        electricBlue: '#00A3FF',
        errors: '#FF647C',
        primaryOpacity: '#003750',
        /* New Colors */
        lightRedGray: '#E8E8E8',
        lightBordes: '#DEDEDE',
        blueDarkGray: '#304346',
        blueGray: '#4B5759',
        blueDeep: '#073045',
        blueSoft: '#638495',
        shadow: '#F3F3F3'
    },

    mobileHeight: {
        header: '80px',
        footer: '56px',
    },

    breakpoints: {
        mobile: '768px',
        desktop: '769px',
    },

    mobile: {
        width: '302px',
    },
}

export const modalPages = {
    content: {
        width: '100%',
        height: '100%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 0,
    },
}

export const modalCards = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 55, 80, 0.98)',
    },
    content: {
        backgroundColor: 'transparent',
        width: 'auto',
        height: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 0,
        padding: 0,
        border: 'none',
    },
}
export const modalFullPage = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 55, 80, 0.98)',
    },
    content: {
        backgroundColor: 'transparent',
        width: 'auto',
        height: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 0,
        padding: 0,
        border: 'none',
        '@media (max-width: 768px)': {
            inset: '0',
            marginRight: '0',
            transform: 'none',
        },
    },
}
