import { useInfiniteQuery } from '@tanstack/react-query'
import { RankingParams, getRanking } from '../../../infrastructure/api-ranking'

export const useRanking = (filters: RankingParams) => {
    const queryKey = ['RankingList', ...Object.values(filters)]

    const {
        data: ranking,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery({
        queryKey,
        queryFn: ({ pageParam }) => {
            const page = filters.page ?? pageParam
            return getRanking({ ...filters, page })
        },
        initialPageParam: 1,
        getNextPageParam: ({ currentPage, totalPages }) =>
            currentPage >= totalPages ? undefined : currentPage + 1,
    })

    const rankingList = ranking?.pages.flatMap(page => page.rankingList) ?? []

    const columnsWidth = rankingList.reduce(
        (accu, item) => {
            const positionLength = `${item.position}`.length
            if (positionLength > accu.positionLength) accu.positionLength = positionLength
            const pulsesLength = `${item.pulsesIn}`.length
            if (pulsesLength > accu.pulsesLength) accu.pulsesLength = pulsesLength
            return accu
        },
        { positionLength: 0, pulsesLength: 0 }
    )

    return {
        ranking,
        isLoading,
        isFetching,
        fetchNextPage,
        hasNextPage,
        columnsWidth,
    }
}
