import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useIntercom } from '../../../hooks/useIntercom'
import LoadingPage from '../../static/LoadingPage'
import { useCreateProfile } from '../hooks/useCreateProfile'
import { useFrequency } from '../hooks/useFrequency'
import { useCourse } from '../hooks/useCourse'
import { CohortsView } from './CohortsView'
import { ConfirmView } from './ConfirmView'
import { CongratsView } from './CongratsView'
import { FrequencyView } from './FrequencyView'
import { useUserCourses } from '../../../hooks/queries/useUserCourses'
import { AsyncView } from './AsyncView'

export const Enroll = () => {
    const { t } = useTranslation('enroll')
    const navigate = useNavigate()
    const { slug } = useParams()
    const { courses, isLoading: isLoadingCourses } = useUserCourses()
    const { create, error, isPending: isCreating, isError, isSuccess } = useCreateProfile()
    const { frequencies, isPending } = useFrequency(slug)
    const { course, isLoading: isLoadingCourse } = useCourse(slug)
    const steps = useState<EnrollProps['steps'][0]>('frequency')
    const choices = useState<EnrollmentData>({})

    const [{ courseId, cohortId }, setChoices] = choices

    const alreadyEnrolled = courses?.some(course => course.slug === slug)

    const networkTitle = t(course?.title ?? 'this network')

    const totalTurns = frequencies?.reduce((acc, { courses }) => acc + courses.length, 0)

    const componentToRender = () => {
        // if (course?.isAsync) return <AsyncView {...{ course, onContinue, steps, choices, networkTitle }} />
        if (isCreating || isLoadingCourses || isLoadingCourse) return <LoadingPage metric="%" />
        if (isPending)
            return <p>{t('Looking for the best options for you, please wait a moment...')}</p>
        if (!totalTurns) return <NoAvailableFrequency {...props} />
        if (steps[0] === 'congrats' || alreadyEnrolled) return <CongratsView />
    }

    const onContinue = () => {
        if (steps[0] === 'confirm' && cohortId) {
            choices[1]({ cohortId })
            create({ cohortId })
        }
        if (steps[0] === 'async' && cohortId) {
            create({ cohortId })
        }
        if (steps[0] === 'cohorts' && cohortId) steps[1]('confirm')
        if (steps[0] === 'frequency' && courseId) steps[1]('cohorts')
    }

    const onBack = () => {
        if (isError) return navigate('/')
        if (steps[0] === 'confirm') return steps[1]('cohorts')
        if (steps[0] === 'cohorts') {
            setChoices(prev => ({ ...prev, cohortId: undefined }))
            return steps[1]('frequency')
        }
        if (steps[0] === 'frequency') return navigate(-1)
    }

    const errorMessage = t('An error occur. Please try again later or contact our support team.')

    const props: EnrollProps = {
        steps,
        choices,
        networkTitle,
        isWidget: true,
        onContinue,
        isError,
        isCreating,
        courseId,
        cohortId,
        onBack,
        error,
        errorMessage,
    }

    useEffect(() => {
        if (!alreadyEnrolled) steps[1]('frequency')
        if (course?.isAsync) steps[1]('async')
        if (isSuccess) steps[1]('congrats')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alreadyEnrolled, course?.isAsync, isSuccess])

    return (
        <>
            {componentToRender() ?? (
                <div className="main-content">
                    {steps[0] === 'async' && course && <AsyncView {...{ course, ...props }} />}
                    {steps[0] === 'frequency' && <FrequencyView {...props} />}
                    {steps[0] === 'cohorts' && <CohortsView {...props} />}
                    {steps[0] === 'confirm' && <ConfirmView {...props} />}
                </div>
            )}
        </>
    )
}

const NoAvailableFrequency = ({ networkTitle }: EnrollProps) => {
    const navigation = useNavigate()
    const { chatWithSales } = useIntercom()
    const { t } = useTranslation('enroll')

    return (
        <NoAvailableFrequencyStyle className="column-center">
            <h1 style={{ fontSize: 'revert' }}>{t(networkTitle)}</h1>
            <p className="big">
                {t('An unexpected issue has occurred. We apologize for any inconvenience.')}
            </p>
            <p className="big">
                <Trans
                    i18nKey="For organizational enrollments, please <0>reach out to us</0>."
                    t={t}
                    components={[
                        <Link key="technical-support" to="" onClick={() => chatWithSales()}>
                            Technical support
                        </Link>,
                    ]}
                />
            </p>
            <div className="buttons column">
                <button className="text" onClick={() => navigation(-1)}>
                    {t('Back')}
                </button>
            </div>
        </NoAvailableFrequencyStyle>
    )
}

const NoAvailableFrequencyStyle = styled.div`
    max-width: 302px;
    text-align: center;
    gap: 0;
    & > .buttons {
        margin-top: 5em;
    }
`

export type EnrollmentData = {
    courseId?: string
    cohortId?: string
}

export type Steps = '' | 'frequency' | 'cohorts' | 'confirm' | 'congrats' | 'async'

export type EnrollProps = {
    steps: [Steps, Dispatch<SetStateAction<Steps>>]
    choices: [EnrollmentData, Dispatch<SetStateAction<EnrollmentData>>]
    networkTitle: string
    isWidget?: boolean
    onContinue: () => void
    onBack?: () => void
    courseId?: string
    cohortId?: string
    isCreating?: boolean
    isError?: boolean
    error?: Error | null
    errorMessage?: string
}
