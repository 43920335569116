import CertificateItem from './CertificateItem'
import React from 'react'
import { capitalizeAll } from '../../helpers/formatters'
import { useCourseInfo } from '../course/hooks/useCourseInfo'
import { useUser } from '../../hooks/queries/useUser'
import { useTranslation } from 'react-i18next'
import { useUserCourses } from '../../hooks/queries/useUserCourses'

export const RegularStudentCert = () => {
    const { t } = useTranslation('certificates')
    const { user, isLoading: isLoadingUser } = useUser()
    const { currentCourseName, currentCourse, isLoading: isLoadingUserCourses } = useUserCourses()
    const { info: { schedule } = {}, isLoading: isLoadingCourse } = useCourseInfo(
        currentCourse?.courseId
    )

    if (isLoadingUser || isLoadingUserCourses || isLoadingCourse) return <p>{t('Loading...')}</p>

    if (!user || !currentCourseName) return null

    const { name, lastname, dni } = user

    const certificate = {
        _id: 'none',
        _profileId: 'none',
        title: 'Certificado de estudiante regular',
        name: capitalizeAll(`${name ?? ''} ${lastname ?? ''}`),
        lastname: capitalizeAll(lastname ?? ''),
        course: capitalizeAll(currentCourseName ?? ''),
        schedule: schedule ?? '',
        idd: dni ?? '',
        content: '',
        issueDate: new Date().toDateString(),
        trayecto: '',
        unidad_academica: '',
        template: 'template_a',
        url: '',
        updatedAt: new Date().toISOString(),
    }

    return <CertificateItem {...certificate} />
}
