import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const LogoutButton = () => {
    const { t } = useTranslation('signUp')
    const navigate = useNavigate()
    return (
        <button className="icon" onClick={() => navigate('/logout')} title={t('Close session')}>
            <FontAwesomeIcon icon={['fal', 'arrow-right-from-bracket']} size="xl" />
        </button>
    )
}
