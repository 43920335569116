import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { Tag } from '../../pages/Home/component/component/CourseCard'
import { useStudyPlan } from '../../pages/course/hooks/useStudyPlan'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { AddCalendarButton } from '../Buttons/AddCalendarButton'
import { ContentCard, getStatus } from './ContentCard'
import { MeetButton } from '../CourseCard/MeetButton'
import { CardGeneral } from '../CardGeneral'
import { useQueryClient } from '@tanstack/react-query'
import { getContentStep } from '../../infrastructure/api-widget'

export const CourseSteps = () => {
    const { t } = useTranslation('dashboard')
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { courseId } = useParams()
    const location = useLocation()
    const [activeSectionId, setActiveSectionId] = useState<string | null>(null)
    const { studyPlan, isPending, todayContent, contentDayDescription } = useStudyPlan(courseId)
    const sectionRef = useRef<HTMLDivElement>(null)
    const [openTodaySection, setOpenTodaySection] = useState<string>()
    const { getCourseData } = useUserCourses()
    const course = getCourseData(courseId)

    const { CourseProgress, progressPercent: percent } = useStudyPlan(courseId)
    const handleOnClick = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
            setOpenTodaySection(todayContent?.section?._id)
        }
    }

    const { plan } = studyPlan || {}
    const { modules = [] } = plan || {}
    const sections = modules.flatMap(module => module.sections)
    const todaySection = sections.find(section => section._id === todayContent?.section?._id)

    const handleStepClick = (stepId: string) => {
        navigate(`/content/step/${stepId}`)
    }

    useEffect(() => {
        const sectionId = new URLSearchParams(location.search).get('sectionId')
        setActiveSectionId(sectionId)
    }, [location.search])

    useEffect(() => {
        modules.flatMap(module =>
            module.sections
                .filter(section => section.abble)
                .flatMap(section => section.steps)
                .forEach(
                    async step =>
                        await queryClient.prefetchQuery({
                            queryKey: ['Content', 'Step', step._id],
                            queryFn: () => getContentStep(step._id),
                        })
                )
        )
    }, [modules, queryClient])

    if (!course) return null
    return (
        <CourseStepsStyled>
            <div className="content-header-container">
                <div className="content-header  border-radius">
                    <CardGeneral
                        header={
                            <AddCalendarButton
                                buttonLabel={t('Add to calendar')}
                                className="primary white"
                                iconCalendar={['fas', 'calendar-plus']}
                            />
                        }
                        main={
                            <>
                                <span className="title">{course.courseName}</span>
                                <div className="progress-bar">
                                    {!course.asyncCourse && (
                                        <>
                                            <span>
                                                {t('{{ percent }}% completed', { percent })}
                                            </span>{' '}
                                            <CourseProgress className="white" />
                                        </>
                                    )}
                                </div>
                            </>
                        }
                        footer={
                            course.asyncCourse ? (
                                <div />
                            ) : (
                                <>
                                    <div>
                                        {course.status === 'finished'
                                            ? t('Your course has ended.')
                                            : ''}
                                    </div>
                                    <MeetButton {...course} isInside={true} />
                                </>
                            )
                        }
                        className="card-general"
                    />

                    {isPending || !studyPlan || course.status === 'finished' ? null : (
                        <TodayContentCard className="border-radius" onClick={handleOnClick}>
                            <div className="section-card-title">
                                <Tag className="section-card-tag">{t('Today you will see')}</Tag>
                                <h4
                                    className="truncate underline-hover"
                                    onClick={() =>
                                        todayContent.step?._id &&
                                        handleStepClick(todayContent.step?._id)
                                    }
                                >
                                    {contentDayDescription}
                                </h4>
                            </div>
                        </TodayContentCard>
                    )}
                </div>
            </div>
            {isPending ? (
                <p>{t('Downloading the study plan...')}</p>
            ) : !studyPlan ? null : (
                <div className="sections">
                    {sections.map((section, idx) => (
                        <ContentCard
                            key={idx + section._id}
                            content={section}
                            status={getStatus(section, todaySection!)}
                            isTodaySection={todaySection?._id === section._id}
                            sectionRef={sectionRef}
                            openTodaySection={openTodaySection!}
                            setOpenTodaySection={setOpenTodaySection}
                            activeSectionId={activeSectionId!}
                        />
                    ))}
                </div>
            )}
        </CourseStepsStyled>
    )
}

export const CourseStepsStyled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.125em;
    padding-bottom: 6em;
    & > .content-header-container {
        background-color: ${defaultTheme.color.offWhite};
        z-index: 1;
        display: grid;
        position: sticky;
        top: 0;
        & > .content-header {
            display: grid;
            grid-gap: 1.125em;
            padding-top: 0.5rem;
            & > .card-general {
                width: 100%;
                min-height: 200px;
                grid-template-rows: [header] max-content [main] max-content [footer] max-content;
                gap: 1em;
                button {
                    height: 42px;
                    min-width: 200px;
                    & > svg {
                        font-size: 15px;
                    }
                }
                & > main {
                    & > .progress-bar {
                        display: flex;
                        align-items: baseline;
                        gap: 1ch;
                    }
                }

                & > header {
                    display: flex;
                    justify-content: flex-end;
                }
                & > footer {
                    border-top: none;
                    padding-top: 0;
                    min-height: max-content;
                }
                @media screen and (max-width: 768px) {
                    grid-template-rows: [main] auto [header] auto;
                    gap: 1em;
                    & > main {
                        order: 1;
                        padding-top: 0;
                        margin-bottom: 0;
                    }
                    button.primary {
                        display: block;
                    }
                    & > header {
                        order: 2;
                        & button {
                            width: 100%;
                            justify-content: center;
                            gap: 1em;
                        }
                    }
                    & > footer {
                        order: 3;
                    }
                }
            }
        }
    }
    & > .sections {
        display: grid;
        gap: 1em;
    }
    .icon {
        font-size: 20px;
    }
`

const TodayContentCard = styled.article`
    width: 100%;
    height: 42px;
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${defaultTheme.color.blueLight};
    color: ${defaultTheme.color.white};
    &:hover {
        cursor: pointer;
    }

    & > .section-card-title {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 1em;
        & > .section-card-tag {
            text-transform: uppercase;
        }
        & > h4 {
            font-size: 1.14em;
            font-weight: 600;
            color: ${defaultTheme.color.electricBlue};
            margin: 0;
        }
    }
    & > .meet-button > button {
        height: 42px;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        padding: 2em;
        height: 76.5px;
        & > *,
        & button {
            width: 100%;
        }
        & .meet-button {
            display: none;
        }
    }
`
