import { GACategory, SendGAEvent } from '../../../helpers/GAEvents'
import { createUser, migrateUser } from '../../../pages/register/api-register'

import Axios from 'axios'
import { useFacebookPixel } from '../../useFacebookPixel'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useRedditPixel } from '../../useRedditPixel'
import { useTikTokPixel } from '../../useTikTokPixel'
import { useTranslation } from 'react-i18next'

export const useCreateUser = () => {
    const { t } = useTranslation('signUp')
    const { executeRecaptcha } = useGoogleReCaptcha()
    const navigate = useNavigate()
    const { fbEvent } = useFacebookPixel()
    const { redditEvent } = useRedditPixel()
    const { tikTokSend } = useTikTokPixel()

    const { mutate: create, ...rest } = useMutation({
        retry: false,
        mutationFn: async (data: Parameters<typeof createUser>[0]) => {
            const captcha_token = (await executeRecaptcha?.('createUser')) ?? ''
            return createUser(data, captcha_token)
        },

        onSuccess: () => {
            fbEvent({ category: 'track', event: 'Lead' })
            redditEvent({ event: 'Lead' })
            tikTokSend('Lead')
            navigate(`/registro/2`)
        },

        onError: (err, { email }, context) => {
            window?.Intercom('update', {
                email,
                language_override: 'es',
            })
            window?.Intercom('showNewMessage', t('Hello, I need help to register in Egg!'))
            SendGAEvent(
                GACategory.ERROR,
                `An error has occurred while a user is trying to register.`
            )
            if (Axios.isAxiosError(err))
                err.response?.status === 409 && navigate(`/registro/error?email=${email}`)
        },
    })

    const { mutate: migrate, mutateAsync: migrateAsync } = useMutation({
        mutationFn: migrateUser,
        onSuccess: () => navigate(`/registro/2`),
        onError: (err, { email }, context) => {
            window?.Intercom('update', { email })
            window?.Intercom(
                'showNewMessage',
                t('Hello, I need help to update my user info in Egg!')
            )
            SendGAEvent(
                GACategory.ERROR,
                `An error has occurred while a user is trying to migrate.`
            )
            if (Axios.isAxiosError(err))
                err.response?.status === 409 && navigate(`/registro/error?email=${email}`)
        },
    })
    return { create, migrate, migrateAsync, ...rest }
}
