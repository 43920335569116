import { useEffect } from 'react'
import { useUserCourses } from './queries/useUserCourses'
import { setPresent } from '../infrastructure/api-widget'
import DayJS from '../helpers/DayJS'

export const useSetPresent = (courseId?: string) => {
    const { getCourseData } = useUserCourses()
    const course = getCourseData(courseId)
    const key = `present-${courseId}`
    const alreadyPresent = !!sessionStorage.getItem(key)

    useEffect(() => {
        if (!courseId) return
        const { nextClass, status } = course ?? {}
        let presentTimer: NodeJS.Timeout | undefined = undefined

        if (alreadyPresent) return console.info('Already set present for this course.')

        if (!nextClass || status !== 'studying')
            return console.info('Course is not available to set present.')

        // If the class is already open, set present
        // If the class is not open yet, set a timer to open it
        if (nextClass.isInRange) setPresent(courseId)
        else {
            const openTime = DayJS(nextClass.open)
            const fromNow = openTime.diff(DayJS(), 'milliseconds')
            if (fromNow < 0) return
            console.info(
                `A timer was set for the user to set present at ${openTime
                    .locale('en')
                    .format()}, ${openTime.locale('en').fromNow()}`
            )
            presentTimer = setTimeout(() => setPresent(courseId), fromNow)
        }

        return () => {
            console.info(`A timer for course ${courseId} was cleared.`)
            clearTimeout(presentTimer)
        }
    }, [alreadyPresent, course, course?.nextClass, course?.status, courseId])

    return { alreadyPresent }
}
