import styled from "styled-components";

export const BtnLink = styled.button`
    font-family: 'Poppins', sans-serif;
    color: ${props => props.theme.color.blue};
    border: none;
    text-decoration: none;
    background: none;
    font-size: 14px;
    margin-left: 0;
    padding-left: 0;
    cursor: pointer;
    &:hover {
        /* color: ${props => props.theme.color.primary}; */
        text-decoration: underline;
    }
`

export const BtnLinkBold = styled(BtnLink)`
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: ${props => props.theme.color.primary};
    font-size: 15px;
    &:hover {
        color: ${props => props.theme.color.electricBlue};
    }
`