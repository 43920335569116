import styled from 'styled-components';
import { BirthDate } from '../FormElements/BirthDate';
import { ChangeEvent, useEffect, useState } from 'react';
import { Country } from '../FormElements/Country';
import { Gender } from '../FormElements/Gender';
import { Input } from '../FormElements/Input';
import { isValidPhone, PhoneNumber } from '../FormElements/PhoneNumber';
import { LanguageSelector } from '../LanguageSelector';
import { Modal } from './Modal';
import { Province } from '../FormElements/Province';
import { useProfile } from '../../pages/profile/hooks/useProfile';
import { useTranslation } from 'react-i18next';
import { useUIContext } from '../../context/UIContext';

export const MyProfileDataModal = () => {
    const { t } = useTranslation('profile')
    const { modalClose, setIsFullScreen } = useUIContext()

    const { user, isLoading, update, api_error } = useProfile()
    const [changes, setChanges] = useState({} as NonNullable<typeof user>)
    const [languageSelect, setLanguageSelect] = useState(false)

    useEffect(() => {
        setIsFullScreen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!user) return
        setChanges(user)
    }, [user])

    const { name, displayName, countryId, gender, lastname, dni, dateBirth, phone, provinceId } =
        changes

    const onChange = ({
        target: { name, value },
    }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) =>
        setChanges(prev => ({
            ...prev,
            [name]: name === 'countryId' || name === 'provinceId' ? +value : value,
        }))

    const onSelectLanguage = () => setLanguageSelect(true)

    const onSave = () => {
        update(changes)
        modalClose()
        setIsFullScreen(false)
    }

    const haveChanges =
        name !== user?.name ||
        displayName !== user?.displayName ||
        countryId !== user?.countryId ||
        provinceId !== user?.provinceId ||
        gender !== user?.gender ||
        phone !== user?.phone ||
        lastname !== user?.lastname ||
        dni !== user?.dni ||
        dateBirth !== user?.dateBirth ||
        languageSelect

    return (
        <Modal>
            <ModalStyled>
                <h2 className="title">{t('Personal information')}</h2>

                <div className="columns">
                    <div className="column">
                        <Input
                            {...{
                                label: t('Name'),
                                id: 'name',
                                name: 'name',
                                value: name ?? '',
                                onChange,
                                autoComplete: 'given-name',
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Alias'),
                                id: 'displayName',
                                name: 'displayName',
                                value: displayName ?? '',
                                onChange,
                                autoComplete: 'off',
                                api_error,
                            }}
                        />
                        <br />

                        <Country
                            {...{
                                value: countryId,
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <Gender
                            {...{
                                value: gender ?? '',
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Company'),
                                id: 'referrer',
                                name: 'referrer',
                                defaultValue: user?.referrer,
                                disabled: true,
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Email'),
                                id: 'email',
                                name: 'email',
                                defaultValue: user?.email,
                                disabled: true,
                                api_error,
                            }}
                        />
                        <br />
                    </div>

                    <div className="column">
                        <Input
                            {...{
                                label: t('Surname'),
                                id: 'lastname',
                                name: 'lastname',
                                value: lastname ?? '',
                                onChange,
                                autoComplete: 'family-name',
                                api_error,
                            }}
                        />
                        <br />

                        <Input
                            {...{
                                label: t('Identity document DNI/RUT'),
                                id: 'dni',
                                name: 'dni',
                                value: dni ?? '',
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <Province
                            {...{
                                value: provinceId ?? '',
                                onChange,
                                countryId: countryId || undefined,
                                api_error,
                            }}
                        />
                        <br />

                        <BirthDate
                            {...{
                                value: dateBirth ?? '',
                                onChange,
                                api_error,
                            }}
                        />
                        <br />

                        <PhoneNumber
                            {...{
                                value: (phone as any) ?? undefined,
                                onChange: (phone: any) =>
                                    setChanges(prev =>
                                        prev ? { ...prev, phone: phone ?? null } : prev
                                    ),
                                error:
                                    phone && !isValidPhone(phone)
                                        ? t('Invalid phone number')
                                        : undefined,
                            }}
                        />
                        <br />

                        <LanguageSelector onSelect={onSelectLanguage} />
                    </div>
                </div>

                <br />
                <div className="buttons">
                    <button className="outlined" onClick={modalClose}>
                        {t('Exit')}
                    </button>
                    <button
                        className="primary"
                        disabled={isLoading || !haveChanges}
                        onClick={onSave}
                    >
                        {t('Save')}
                    </button>
                </div>
            </ModalStyled>
        </Modal>
    )
}

const ModalStyled = styled.main`
    overflow: auto;
    height: -webkit-fill-available;
    height: 100vh;
    max-height: calc(100vh - 180px);
    padding: 1.5em;
    .title {
        margin: 0;
        margin-bottom: 1em;
    }
    .columns {
        display: flex;
        gap: 20px;

        .column {
            display: inline-block;
            width: 363px;
        }

        .column-big {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        overflow: auto;
        max-height: 100vh;
        margin: 0;
        padding: 2em;
        padding-bottom: 6em;
        .columns {
            flex-direction: column;
            .column {
                width: auto;
            }
        }
        .buttons {
            justify-content: center !important;
            button {
                width: -webkit-fill-available;
                width: 100%;
            }
        }
    }
`
