import Tooltip from '../../Atoms/Tooltip/Tooltip.component';
import useNavBarItems, { ItemMenuProps } from './useNavBarItems';
import { BottomMenuRows, MenuRow, TopMenuRows } from './NavBarMobile';
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef
    } from 'react';
import {
    Dropdown,
    DropdownClose,
    DropdownItem,
    Menu,
    MenuItemContainer
    } from './NavBar.styles';
import { Icon } from '../../../Icon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const NavBarDesktop = () => {
    const componentRef = useRef()
    const { topMenuItems, bottomMenuItems, states } = useNavBarItems()

    const { activeItem, setActiveItem, visibleIndexContent, setVisibleIndexContent } = states

    useOutsideClick(componentRef.current!, () => {
        setVisibleIndexContent(false)
    })

    return (
        <Menu ref={componentRef as any} className="only-desktop">
            <MenuItemContainer>
                <div>
                    <TopMenuRows>
                        {topMenuItems.map(item => (
                            <Item item={item} states={states} key={item.label} />
                        ))}
                    </TopMenuRows>
                </div>
                <BottomMenuRows>
                    {bottomMenuItems.map(item => (
                        <Item item={item} states={states} key={item.label} />
                    ))}
                </BottomMenuRows>
            </MenuItemContainer>
            {activeItem?.children && (
                <Dropdown open={visibleIndexContent}>
                    <DropdownClose
                        className="icon toggle"
                        onClick={() => {
                            setVisibleIndexContent(!visibleIndexContent)
                            setActiveItem({ ...activeItem, label: '' })
                        }}
                    >
                        <Icon icon={['fal', 'times']} size="2xl" />
                    </DropdownClose>
                    <DropdownItem>{activeItem?.children}</DropdownItem>
                </Dropdown>
            )}
        </Menu>
    )
}

export default NavBarDesktop

const Item = ({ item, states }: ItemProps) => {
    const {
        activeIcon,
        icon,
        label,
        hidden,
        svgIcon,
        activeSvgIcon,
        onClick,
        logo,
        isActive,
        children,
    } = item
    const { setActiveItem, visibleIndexContent, setVisibleIndexContent } = states

    const selectIconOrImage = (svgIconItem: ReactNode, iconItem?: IconProp) =>
        svgIconItem ?? (iconItem ? <Icon icon={iconItem} /> : <img src={logo} alt="EGG" />)

    const iconToUse = selectIconOrImage(
        isActive ? activeSvgIcon : svgIcon,
        isActive ? activeIcon : icon
    )
    const handleMenuItemOnClick = (item: ItemMenuProps) => {
        setActiveItem(item)
        if (children) {
            setVisibleIndexContent(!visibleIndexContent)
            if (visibleIndexContent) {
                setActiveItem({ label: '', isActive: false })
            }
        }
        onClick?.()
    }

    if (hidden) return null
    return (
        <Tooltip placement="rightCenter" text={label} key={label}>
            <MenuRow
                onClick={() => {
                    handleMenuItemOnClick(item)
                }}
                active={isActive}
            >
                {iconToUse}
            </MenuRow>
        </Tooltip>
    )
}

export function useOutsideClick(ref: any, onClickOut: () => void) {
    const onClick = useCallback(
        ({ target }: any) => {
            !ref?.contains(target) && onClickOut?.()
        },
        [ref, onClickOut]
    )

    const removeEventListener = useMemo(() => {
        document.addEventListener('click', onClick)
        return () => document.removeEventListener('click', onClick)
    }, [onClick])

    useEffect(() => {
        return removeEventListener
    }, [removeEventListener])
}

interface ItemProps {
    item: ItemMenuProps
    states: ItemStates
}

type ItemStates = {
    activeItem: ItemMenuProps | undefined
    setActiveItem: Dispatch<SetStateAction<ItemMenuProps | undefined>>
    visibleIndexContent: boolean
    setVisibleIndexContent: Dispatch<any>
}
