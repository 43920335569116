import styled from 'styled-components'
import { MyCourses } from './MyCourses'

export const Home = () => {
    return (
        <Section>
            <MyCourses />
        </Section>
    )
}

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 4em;
    padding-bottom: 4em;
`
