import styled from 'styled-components'
import { BLOCKS, INLINES, Block, Inline } from '@contentful/rich-text-types'
import { useNavigate } from 'react-router-dom'
import { extractYouTubeID } from '../helpers/validators'
import { defaultTheme } from '../styles/themes/defaultTheme'
import { Icon } from '../components/Icon'
import { ReactNode } from 'react'
import { Options } from '@contentful/rich-text-react-renderer'
import { CodeBlock } from '../components/CodeBlock'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const useContentfulRenderOptions = (
    _profileId: string,
    stepId: string,
    moduleId: string,
    sectionId: string,
    _classId: string,
    email: string
) => {
    const navigate = useNavigate()

    const options: Options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node: Node, children: ReactNode) => <h1 children={children} />,
            [BLOCKS.HEADING_2]: (node: Node, children: ReactNode) => <h2 children={children} />,
            [BLOCKS.HEADING_3]: (node: Node, children: ReactNode) => <h3 children={children} />,
            [BLOCKS.HEADING_4]: (node: Node, children: ReactNode) => <h4 children={children} />,
            [BLOCKS.HEADING_5]: (node: Node, children: ReactNode) => <h5 children={children} />,
            [BLOCKS.HEADING_6]: (node: Node, children: ReactNode) => <h6 children={children} />,

            [BLOCKS.HR]: () => <hr />,

            [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => {
                let type: string | undefined = node?.content?.[0]?.marks?.[0]?.type
                return type === 'code' ? (
                    <CodeBlock children={children} />
                ) : (
                    <p children={children} />
                )
            },

            [BLOCKS.QUOTE]: (_: Node, children: ReactNode) => <Blockquote children={children} />,

            [INLINES.HYPERLINK]: (node: any, children: ReactNode) => {
                const uri = node?.data?.uri
                const value = node?.content[0]?.value
                if (!uri || !value) return children
                const isForm = uri?.includes('typeform.com')
                const isNPS = uri?.includes('form_type=nps')

                const href = isForm
                    ? `${uri}&profile_id=${_profileId}&step_id=${stepId}&module_id=${moduleId}&section_id=${sectionId}&class_id=${_classId}&email=${email}`
                    : uri

                if (isForm)
                    return (
                        <ExternalLink
                            href={href}
                            icon={['far', !isNPS ? 'ballot-check' : 'badge-check']}
                            value={value}
                        />
                    )

                if (uri?.includes('youtu.be'))
                    return <ExternalLink href={uri} icon={['far', 'film']} value={value} />

                if (uri?.includes('youtube.com/watch'))
                    return (
                        <Iframe
                            src={`https://www.youtube.com/embed/${extractYouTubeID(uri)}`}
                            title={value}
                            allow="fullscreen;"
                        />
                    )

                return <ExternalLink href={href} value={value} />
            },

            [BLOCKS.TABLE]: (_: Node, children: ReactNode) => <Table children={children} />,

            [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
                const contentType = node?.data?.target?.fields?.file?.contentType
                const href = node?.data?.target?.fields?.file?.url
                const title = node?.data?.target?.fields?.title
                if (!contentType || !href) return null

                if (contentType.includes('image/')) return <img src={`https:${href}`} alt={title} />
                if (contentType.includes('application/'))
                    return <ExternalLink href={href} icon={['far', 'file']} value={title} />
            },

            [INLINES.EMBEDDED_ENTRY]: (node: Node, children: ReactNode) => (
                <button
                    className="primary"
                    children={node?.data?.target?.fields?.title}
                    onClick={() => navigate(`/content/step/${node?.data?.target?.sys?.id}`)}
                />
            ),
        },
    }

    return {
        options,
    }
}

const ExternalLink = ({ href, value, icon }: { href: string; value: string; icon?: IconProp }) => {
    return (
        <Link href={href} target="_blank" rel="noopener noreferrer" className="button outlined">
            {icon && <Icon icon={icon} size="xl" />}
            <span>{value}</span>
        </Link>
    )
}

type Node = Block | Inline

const Link = styled.a`
    text-decoration: underline !important;
    color: #00a3ff !important;
    &:hover {
        color: #105df9 !important;
    }
    & > svg {
        margin: 0 0.2em 0 0 !important;
        height: 18px;
    }
`

const Iframe = styled.iframe`
    width: 100%;
    min-height: 24em;
    border: 0;
    border-radius: 6px;
    width: 100%;
    width: '--webkit-fill-available';
    border: 0;
    border-radius: 6px;
`

const Blockquote = styled.blockquote`
    margin: 1em 0;
    border-radius: 6px;
    padding: 2em;
    background-color: ${defaultTheme.color.lightGray};
`

const Table = styled.table`
    width: 100%;
    border-radius: 6px;
    border-collapse: separate;

    th:first-child {
        border-top-left-radius: 6px;
    }

    th:last-child {
        border-top-right-radius: 6px;
    }

    tr:nth-child(odd) {
        background-color: ${defaultTheme.color.offWhite};
    }

    th,
    td {
        padding: 0.5em;
        border: 1px solid ${defaultTheme.color.lightGray};
    }

    th {
        color: white;
        background-color: ${defaultTheme.color.primary};
    }

    p {
        margin: 0.5em 0;
        text-align: center;
        all: unset;
    }
`
