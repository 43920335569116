import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useUser } from '../../../hooks/queries/useUser'
import { RankingParams } from '../../../infrastructure/api-ranking'
import { ProfileRanking } from '../../../interfaces/api-ranking'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import { CommonRankingCardStyle } from './CommonRankingCardStyle'
import AvatarEgg from '../../../components/Misc/AvatarEgg'

type RankingUserCardType = {
    userRanking: ProfileRanking | undefined
    searchValue: string
    setRankingListFilters: React.Dispatch<React.SetStateAction<RankingParams>>
    isActive: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const RankingUserCard = (props: RankingUserCardType) => {
    return (
        <RankingUserCardStyle>
            {props.userRanking ? <RankedUser {...props} /> : <NonRankedUser />}
        </RankingUserCardStyle>
    )
}

const RankedUser = ({
    userRanking,
    searchValue,
    setRankingListFilters,
    isActive,
}: RankingUserCardType) => {
    const { t } = useTranslation('ranking')
    const { position, pulsesIn } = userRanking ?? {}
    const { user } = useUser()

    const boltColor = require('../../../assets/bolts/rayo_color.svg').default

    const updateFilters = (isActive: boolean) =>
        isActive
            ? setRankingListFilters(prev => ({
                  ...prev,
                  myposition: `${position}`,
                  page: 'me',
                  name: '',
              }))
            : setRankingListFilters(prev => ({
                  ...prev,
                  myposition: '',
                  page: '',
              }))

    const buttonClass = !isActive[0] ? 'inactive' : 'active'

    const toggleButton = () => {
        isActive[1](!isActive[0])
        updateFilters(!isActive[0])
    }
    return (
        <div className="ranking-card-container ranking-container">
            <h1 className="ranking-card-position bar-light"># {position}</h1>
            <div className="ranking-card-icon only-desktop">
                <AvatarEgg
                    size={{ mobile: 30, desktop: 45 }}
                    title={`${user?.name} ${user?.lastname}`}
                />
            </div>
            <p className="ranking-card-display-name capitalize">{user?.displayNameOrName}</p>
            <div className="ranking-card-position-button bar-light">
                <button
                    className={`primary ${buttonClass}`}
                    onClick={toggleButton}
                    disabled={!!searchValue}
                >
                    <FontAwesomeIcon icon={['fas', 'map-pin']} />
                    <span className="only-desktop">{t('Position')}</span>
                </button>
            </div>
            <img className="ranking-card-pulse-icon" src={boltColor} alt="Bolt" />
            <h1 className="ranking-card-pulses">{pulsesIn}</h1>
        </div>
    )
}

const NonRankedUser = () => {
    const { t } = useTranslation('ranking')
    const navigate = useNavigate()

    return (
        <div className="ranking-card-container no-ranking-container">
            <FontAwesomeIcon
                icon={['far', 'ranking-star']}
                className="blue ranking-card-icon"
                style={{ fontSize: '30px' }}
            />
            <div className="column-center">
                <p className="big ranking-card-title">
                    {t('Show the world your power of cooperation')}
                </p>
                <p className="big ranking-card-subtitle">
                    {t('Sign up for a course and be part of the Egg Ranking.')}
                </p>
            </div>
            <div className="ranking-card-position-button">
                <button className="text white-text" onClick={() => navigate('/ecosystem')}>
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    {t('Sign up now')}
                </button>
            </div>
        </div>
    )
}

const RankingUserCardStyle = styled(CommonRankingCardStyle)`
    margin: 40px 0;
    color: ${defaultTheme.color.white};
    background-color: ${defaultTheme.color.primary};
    box-sizing: border-box;
    padding: 20px 40px;
    border: 1px solid ${defaultTheme.color.lightGray};
    border-radius: 12px;
    .ranking-container {
        grid-template-columns: max-content max-content auto max-content max-content max-content;
        .ranking-card-icon {
            color: ${defaultTheme.color.primary};
        }
    }
    .no-ranking-container {
        grid-template-columns: max-content max-content auto;
        .column-center {
            align-items: flex-start;
            p {
                margin: 0;
            }
        }
        .ranking-card-title {
            font-weight: 700;
        }
        .ranking-card-subtitle {
            opacity: 0.5;
        }
    }
    .ranking-card-position-button {
        text-align: end;
        button {
            font-size: 16px;
            border: none !important;
            &.active {
                background-color: unset;
                color: ${defaultTheme.color.electricBlue} !important;
            }
            &:not(:disabled):hover {
                background-color: unset;
                border-color: unset;
                color: ${defaultTheme.color.electricBlue} !important;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        & > .ranking-card-container.ranking-container {
            grid-template-columns: max-content 1fr max-content max-content max-content;
            & > .ranking-card-position-button {
                padding-right: 0;
                button {
                    padding: 0 0.5em 0 0;
                    text-align: center;
                    min-width: fit-content;
                }
            }
        }
        & > .ranking-card-container.no-ranking-container {
            grid-template-columns: 1fr;
            justify-items: center;
            gap: 2em;
            padding: 1em 0;
            & .ranking-card-title {
                margin-bottom: 1em;
            }
            & p {
                text-align: center;
            }
        }
    }
`
