import { isProduction } from '../interfaces/constants'

export const useRedditPixel = () => {
    const redditEvent = ({ event, customEvent }: RedditEvent) => {
        const eventToSend = !customEvent
            ? window.rdt?.('track', event)
            : window.rdt?.('track', event, customEvent)
        return isProduction ? eventToSend : console.warn('New Reddit event', { event, customEvent })
    }
    return { redditEvent }
}
export const initializeRedditPixel = (pixelId: string) => {
    const script = document.createElement('script')
    script.innerHTML = `
    !function (w, d) {
        if (!w.rdt) {
            var p = w.rdt = function () { p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments) }; 
            p.callQueue = []; 
            var t = d.createElement("script"); 
            t.src = "https://www.redditstatic.com/ads/pixel.js", t.async = !0; var s = d.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(t, s)
        }
    }(window, document); 
    `
    document.body.appendChild(script)
    window.rdt('init', pixelId, {
        optOut: false,
        useDecimalCurrencyValues: true,
    })
}

declare global {
    interface Window {
        rdt: (...args: any[]) => void
    }
}

type RedditEvent = {
    event: 'PageVisit' | 'Lead' | 'Custom'
    customEvent?: { customEventName: 'Enrolled' }
}
