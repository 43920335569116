import logo from '../assets/egg_no_shadow.svg'

const  useNotification = () => {

  const notification  = (title: string, message: string) => {
    new Notification(title, {
      body: message,
      icon: logo
    })
  }

    const showNotification = async (title: string, message: string) => {
      if(!('Notification' in window)){
        console.error("This brower does not support desktop notification")
        return false;
      }

      const permission = await Notification.permission;
      if(permission === 'granted') {
        notification(title, message)
        return;
      }

      const newPermission = await getPermissionNotification();
      if(newPermission === 'granted'){
        notification(title, message)
      }
    }

    const getPermissionNotification = async () => {
      return await Notification.requestPermission();
    }

  
    return {
      showNotification, getPermissionNotification
    };
  }

  export default useNotification