import { InfiniteData } from '@tanstack/react-query'
import styled from 'styled-components'
import AvatarEgg from '../../../components/Misc/AvatarEgg'
import { Ranking } from '../../../interfaces/api-ranking'
import { defaultTheme } from '../../../styles/themes/defaultTheme'
import { CommonRankingCardStyle } from './CommonRankingCardStyle'

export const RankingListCard = ({
    ranking,
    columnsWidth: { positionLength, pulsesLength },
    userPosition,
}: {
    ranking: InfiniteData<Ranking>
    columnsWidth: { positionLength: number; pulsesLength: number }
    userPosition: number | undefined
}) => {
    const boltColor = require('../../../assets/bolts/rayo_color.svg').default
    const rankingListLength = ranking.pages[0].rankingList.length

    return (
        <>
            {!rankingListLength ? (
                <div>No hay resultados</div>
            ) : (
                <RankingCardStyle maxLengthPosition={positionLength} maxLengthPulses={pulsesLength}>
                    {ranking.pages.map(({ rankingList }) =>
                        rankingList.map(
                            ({ position, name, lastName, pulsesIn, urlImage, _userId }) => (
                                <div
                                    key={_userId}
                                    className={`ranking-card-container ranking-container ${
                                        userPosition === position && 'ranking-card-user'
                                    }`}
                                >
                                    <h1 className="ranking-card-position bar-dark"># {position}</h1>

                                    <div className="ranking-card-icon only-desktop">
                                        <AvatarEgg
                                            src={urlImage ?? ''}
                                            size={{ mobile: 30, desktop: 45 }}
                                            title={`${name} ${lastName}`}
                                            isExternal={true}
                                        />
                                    </div>
                                    <p className="ranking-card-display-name capitalize">{`${name} ${lastName}`}</p>
                                    <img
                                        className="ranking-card-pulse-icon"
                                        src={boltColor}
                                        alt="Bolt"
                                    />
                                    <h1 className="ranking-card-pulses">{pulsesIn}</h1>
                                </div>
                            )
                        )
                    )}
                </RankingCardStyle>
            )}
        </>
    )
}

const RankingCardStyle = styled(CommonRankingCardStyle)<{
    maxLengthPosition: number
    maxLengthPulses: number
}>`
    background-color: ${defaultTheme.color.white};
    padding: 20px;
    border: 1px solid ${defaultTheme.color.lightGray};
    border-radius: 12px;
    .ranking-card-container {
        border-bottom: 1px solid ${defaultTheme.color.lightGray};
        padding: 22px 20px;
        display: grid;
        align-items: center;
        grid-template-columns:
            ${({ maxLengthPosition }) => `${maxLengthPosition + 5}em`}
            max-content
            auto
            max-content
            ${({ maxLengthPulses }) => `${maxLengthPulses}em`};
        gap: 1em;
        &:last-child {
            border-bottom: none;
        }
    }
    .ranking-card-user {
        background-color: #e2f3fa;
        border-radius: 12px;
    }
    .ranking-points {
        img {
            padding: 0;
            align-items: start;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        & > .ranking-card-container {
            grid-template-columns: max-content 1fr max-content max-content;
            padding: 10px 20px;
        }
    }
`
