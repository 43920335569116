import { isProduction } from '../interfaces/constants'

export const useFacebookPixel = () => {
    const fbEvent = ({ category, event }: FBEvent) =>
        isProduction
            ? window.fbq?.(category, event)
            : console.warn('New Facebook event', { category, event })
    return { fbEvent }
}

export const initializeFacebookPixel = (pixelId: string) => {
    if ('fbq' in window) return

    const script = document.createElement('script')
    script.innerHTML = `
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
        if (!f._fbq) f._fbq = n;
        n.push = n; 
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    `
    document.body.appendChild(script)
    window.fbq?.('init', pixelId)
}

declare global {
    interface Window {
        fbq?: (action: string, ...args: any[]) => void
    }
}

type FBEvent = {
    category: FBQCategory
    event: FBQEvent
}

type FBQCategory = 'track' | 'trackCustom'
type FBQEvent = 'PageView' | 'Lead' | 'Enrolled'
