import { ComponentProps, useEffect, useState } from 'react'
import DayJS, { humanize } from '../../helpers/DayJS'

import { AssignationBar } from './AssignationBar'
import { Icon } from '../Icon'
import { setNewAccessToken } from '../../infrastructure/apis'
import { useIntercom } from '../../hooks/useIntercom'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export const GoToCourseButton = ({
    courseId,
    requirePayment,
    status,
    start,
    asyncCourse,
    isInside,
    ...rest
}: ComponentProps<typeof AssignationBar>) => {
    const { t } = useTranslation('dashboard')
    const { chatWithSupport } = useIntercom()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [btnLabel, setBtnLabel] = useState(t('Content') as string)

    useEffect(() => {
        const interval = setInterval(() => {
            if (isInside) return null

            const now = DayJS()
            const startDate = DayJS(start)
            const isTodayAndBefore = startDate.isToday() && now.isBefore(startDate)

            if (status === 'enrolled')
                setBtnLabel(t('Start in {{ startDate }}', { startDate: startDate.format('LL') }))
            if (status === 'finished') setBtnLabel(t('Review content'))
            if (asyncCourse) setBtnLabel(t('Content'))
            if (requirePayment) setBtnLabel(t('Secure my spot'))
            if (isTodayAndBefore)
                setBtnLabel(t('Start {{ startIn }}', { startIn: humanize(startDate) }))

            // Transition for starting the course
            if (status === 'enrolled' && now.isAfter(startDate))
                queryClient.invalidateQueries({ queryKey: ['User', 'Courses'] })
        }, 1000)
        return () => clearInterval(interval)
    }, [asyncCourse, isInside, queryClient, requirePayment, start, status, t])

    if (isInside) return null

    const goToCourse = () => {
        if (requirePayment) return chatWithSupport('')
        setNewAccessToken(rest['access-token'])
        navigate(`/dashboard/${courseId}`)
    }

    return (
        <button className="primary white" onClick={goToCourse}>
            <Icon icon={['fas', 'folder']} /> {btnLabel}
        </button>
    )
}
