import { Duration } from 'dayjs/plugin/duration'
import DayJS from './DayJS'
import { capitalize } from './formatters'

export const parseDateFromViewToApi = (date: string) => DayJS(date).toISOString()

// martes 18 de abril
export const parseDateToDDDD_D_de_MMMM_HH = (date: string) => capitalize(DayJS(date).format('LLLL'))
export const parseDateToDDDD_D_de_MMMM = (date: string) => capitalize(DayJS(date).format('LL'))

export const parseHour = (hour: number, minutes?: number, operation?: 'add' | 'subtract') => {
    const localTimezone = DayJS.tz.guess()
    let time = DayJS().utc().hour(hour).minute(0).second(0).tz(localTimezone)

    if (minutes && operation) {
        time = operation === 'add' ? time.add(minutes, 'minute') : time.subtract(minutes, 'minute')
    }

    return time.format('H:mm')
}

export const generateTimeRange = (
    from: number,
    to: number,
    options?: { minutes: number; operation: 'add' | 'subtract' }
) => ({
    start: !options ? parseHour(from) : parseHour(from, options.minutes, options.operation),
    end: !options ? parseHour(to) : parseHour(to, options.minutes, options.operation),
})

export const rtf = new Intl.RelativeTimeFormat(undefined, {
    localeMatcher: 'best fit',
    numeric: 'auto',
    style: 'long',
})
