import {
    API_ERROR,
    DeleteAuthResponse,
    GetGoogleCalendarLinkResponse,
    GetUserCoursesResponse,
    GetUserInfoResponse,
    PostAuthBody,
    PostAuthResponse,
    PostRefreshToken,
    ValidateCredentialsProps,
    ValidateCredentialsResponse,
    WhoAmIResponse,
} from '../interfaces/api-activation'
import Axios from 'axios'
import { refreshInterceptor, setNewAccessToken } from './apis'
import { GACategory, SendGAEvent } from '../helpers/GAEvents'

import DayJS from '../helpers/DayJS'
import { fbSignOut } from './firebase'
import { extendCourseData } from '../helpers/courses'

const { REACT_APP_SIGNUP_API } = process.env

if (!REACT_APP_SIGNUP_API)
    console.error('REACT_APP_SIGNUP_API to be declared on your system environments variables')

const baseURL = `${REACT_APP_SIGNUP_API}`

export const ApiActivation = Axios.create({
    baseURL,
})

const tz = DayJS.tz.guess()

ApiActivation.interceptors.response.use(
    response => response,
    error => refreshInterceptor(error, ApiActivation)
)

export const getWhoAmI = (data: { email: string; captcha_token: string }) =>
    ApiActivation.post<WhoAmIResponse>(`/v1/users/who-am-i`, data).then(({ data }) => data.data)

export const getUserInfo = () =>
    ApiActivation.post<GetUserInfoResponse>('/v1/users/me', { tz }).then(({ data }) => data.data)

export const getUserCourses = () =>
    ApiActivation.get<GetUserCoursesResponse>('/v1/users/courses').then(({ data }) =>
        data.data.courses.map(extendCourseData)
    )

export const validateCredentials = ({ captcha_token, ...rest }: ValidateCredentialsProps) =>
    ApiActivation.post<ValidateCredentialsResponse>(`/v1/auth`, { captcha_token, ...rest }).then(
        ({ data }) => {
            SendGAEvent(GACategory.LOGIN, `Login`)
            const token = data.data['access-token']
            token && setNewAccessToken(token)
            return data.data
        }
    )

export const postAuth = (data: PostAuthBody) =>
    ApiActivation.post<PostAuthResponse>(`/v1/auth`, data, {
        withCredentials: true,
    }).then(({ data }) => {
        SendGAEvent(GACategory.LOGIN, `Login`)
        const token = data.data['access-token']
        token && setNewAccessToken(token)
        return data.data
    })

export const refreshAccessToken = () =>
    Axios.get<PostRefreshToken>(`${baseURL}/v1/auth`, {
        withCredentials: true,
    })
        .then(({ data }) => (data['access-token'] ? data['access-token'] : null))
        .catch(({ code }: API_ERROR) => {
            // eslint-disable-next-line no-restricted-globals
            if (code === 403) history.pushState(null, '', '/logout')
            return null
        })

export const clearSession = () =>
    ApiActivation.delete<DeleteAuthResponse>(`/v1/auth`, {
        withCredentials: true,
    }).then(({ data }) => {
        SendGAEvent(GACategory.EXIT, `Logout`)
        setNewAccessToken(null)
        console.info(data.message)
    })

export const signOut = () =>
    ApiActivation.delete('/v1/auth', {
        withCredentials: true,
    }).then(response => {
        delete ApiActivation.defaults.headers.common.Authorization
        fbSignOut()
    })

export const authTest = () => ApiActivation.get('/v1/auth/check')

export const googleCalendarLink = (profileId: string, captcha_token: string) =>
    ApiActivation.post<GetGoogleCalendarLinkResponse>(`/v1/servicies/${profileId}/calendarLink`, {
        captcha_token,
    }).then(({ data }) => data.data)

export const changePassword = ({ token, ...rest }: ResetPasswordProps) =>
    ApiActivation.post(`/v1/auth/password-change`, rest, {
        headers: { Authorization: `Bearer ${token}` },
    })

interface ResetPasswordProps {
    password: string
    token: string
}
