import { Dispatch, SetStateAction } from 'react'
import { Icon } from '../Icon'
import { MeetButton } from '../CourseCard/MeetButton'
import { MyTeam } from '../MyTeam/MyTeam'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import styled from 'styled-components'
import { useUserCourses } from '../../hooks/queries/useUserCourses'
import { useNavigate } from 'react-router-dom'
import { useActiveCourse } from '../../pages/course/hooks/useActiveCourse'
import { useContentDay } from '../../pages/course/hooks/useContentDay'
import { useStudyPlan } from '../../pages/course/hooks/useStudyPlan'

export const MobileFooterBar = ({
    showWidget,
    setShowWidget,
}: {
    showWidget: boolean
    setShowWidget: Dispatch<SetStateAction<boolean>>
}) => {
    const navigate = useNavigate()
    const { currentCourse } = useUserCourses()
    const { studyPlan } = useStudyPlan(currentCourse?.courseId)
    const { activeCourse } = useActiveCourse()
    const { contentDay } = useContentDay(currentCourse?.courseId)
    const alreadyAssigned = currentCourse?.alreadyAssigned

    const firstSectionAvailable = studyPlan?.plan?.modules
        .filter(module => module._id === contentDay?.contentDist.firstModule)[0]
        ?.sections?.filter(section => section._id === contentDay?.contentDist.firstSection)[0]
    const currentStep =
        firstSectionAvailable?.steps[0]?._id ??
        studyPlan?.plan?.modules?.[0]?.sections?.[0]?.steps?.[0]?._id
    const onClick = () => {
        if (activeCourse?.hasExternalContent && currentStep) {
            navigate(`/content/step/${currentStep}`)
        }
    }
    return (
        <ContentFooterStyle>
            {showWidget && (
                <div className="widget">
                    <MyTeam />
                </div>
            )}
            <div className="buttons">
                <button
                    className="icon"
                    onClick={() => {
                        onClick?.()
                        setShowWidget(false)
                    }}
                >
                    <Icon icon={['fas', 'folder']} className="button-icon" />
                </button>

                <button
                    className="icon"
                    onClick={() => setShowWidget(!showWidget)}
                    disabled={!alreadyAssigned}
                >
                    <Icon icon={['fas', 'bolt']} />
                </button>

                {currentCourse && <MeetButton {...currentCourse} isInside={true} iconOnly={true} />}
            </div>
        </ContentFooterStyle>
    )
}

const ContentFooterStyle = styled.nav`
    width: 100%;
    height: 100%;
    border-top: 1px solid ${defaultTheme.color.lightGray};

    & > .buttons {
        height: 100%;
        display: flex;
        justify-content: space-evenly !important;
        align-items: center;
    }

    & > .widget {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100dvh - ${defaultTheme.mobileHeight.footer});
        width: 100%;
        z-index: 3;
        overflow: hidden;
        ::-webkit-scrollbar {
            width: 0px;
        }
        scroll-behavior: smooth;
        & > section {
            width: 100%;
            min-height: 100%;
            height: 100%;
            z-index: 1;
            padding: 1.5em;
            border-radius: 0;
            box-shadow: none;
        }
    }
`
