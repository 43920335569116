const key = 'URLSearchParams'

export const decodeSearchParams = <T = Record<string, string>>(searchParams: any): T => {
    return [...searchParams.entries()].reduce((acc, [key, val]) => {
        try {
            return {
                ...acc,
                [key]: JSON.parse(val),
            }
        } catch {
            return {
                ...acc,
                [key]: val,
            }
        }
    }, {})
}

export const persistUrlParameters = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const searchParams = decodeSearchParams(urlParams)
    persistOnSession(searchParams)
}

export const persistOnSession = (params: Params) =>
    sessionStorage.setItem(key, JSON.stringify(params))

export const updateSessionParams = (params: Params) =>
    persistOnSession({ ...getUrlParameters(), ...params })

export const getUrlParameters = () => {
    try {
        const params = sessionStorage.getItem(key)
        if (!params || !params.startsWith('{') || !params.endsWith('}')) return {} as Params
        return JSON.parse(params) as Params
    } catch (error) {
        console.log(error)
        return {} as Params
    }
}

type Params = Record<string, string | number | boolean | undefined>
