import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { Modal } from './Modal'

export const UserBalanceGlossary = () => {
    const { t } = useTranslation('wallet')

    return (
        <Modal>
            <Styles>
                <Item>
                    <div className="left">
                        <Icon icon={['fal', 'bolt']} size="2xl" title={t('Pulses')} />
                        {t('Pulses')}
                    </div>
                    <div className="right">
                        {t(
                            'It is the recognition that people give to those who help them. It is the existence of an act of cooperation.'
                        )}
                    </div>
                </Item>

                <Item>
                    <div className="left">
                        <Icon
                            icon={['fal', 'shoe-prints']}
                            rotation={270}
                            size="2xl"
                            title={t('Steps')}
                        />
                        {t('Steps')}
                    </div>
                    <div className="right">
                        {t(
                            "It is the minimum unit of Egg content that represents the student's academic progress and use of the platform. Approximately 1 hour."
                        )}
                    </div>
                </Item>

                <Item>
                    <div className="left">
                        <Icon
                            icon={['fal', 'coin-blank']}
                            rotation={270}
                            size="2xl"
                            title={t('EggCoins')}
                        />
                        {t('EggCoins')}
                    </div>
                    <div className="right">
                        {t(
                            'It is the currency of the Egg platform. It is used to buy products and services.'
                        )}
                    </div>
                </Item>
            </Styles>
        </Modal>
    )
}

const Styles = styled.div`
    max-width: 70ch;
    @media screen and (max-width: 768px) {
        padding: 1em;
    }
`

const Item = styled.section`
    display: grid;
    grid-template-columns: 145px auto;
    place-content: center;
    margin: 3em 0;

    .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5em;
        font-weight: 700;
        font-size: 1.2em;
        border-right: 4px solid;
    }

    .right {
        margin-left: 1em;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 2em;
        .left {
            border-right: none;
        }
    }
`
